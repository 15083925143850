/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    a: "a",
    h3: "h3"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Consumer Generated Content Guidelines"
    }), "\n", _jsx(_components.p, {
      children: "Checkayou.com is a public site; therefore, we ask that you follow some basic rules. We moderate all reviews before posting them and reserve the right to deny any review at our absolute discretion. Your review should describe your experience using the product and include details about what you liked or disliked."
    }), "\n", _jsx(_components.p, {
      children: "Here are some examples of the type of content that can cause a review to be denied:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Offensive, abusive or prejudicial language"
      }), "\n", _jsx(_components.li, {
        children: "Reviews that are not relevant to the product being reviewed"
      }), "\n", _jsx(_components.li, {
        children: "Reviews that contain a price reference"
      }), "\n", _jsx(_components.li, {
        children: "Reviews that attempt to solicit responses from others (creating a forum)"
      }), "\n", _jsx(_components.li, {
        children: "Reviews that contain content about competitors"
      }), "\n", _jsx(_components.li, {
        children: "Mentions of personally identifiable information"
      }), "\n", _jsx(_components.li, {
        children: "Customer service issues, shipping issues, return policies or safety concerns. If you have concerns about the safety of a product, please contact us directly with the information provided below. Do not submit this feedback through a product review."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["We reserve the right not to publish your review should these Guidelines or Terms and Conditions be violated, or for any other reasons in our entire discretion. It is not possible for you to edit/remove your review after submission. However, if you wish to have your review removed, you can contact our Customer Relations team at ", _jsx(_components.a, {
        href: "mailto:info@checkayou.com?subject=Customer%20Relations%20Question",
        children: "info@checkayou.com"
      })]
    }), "\n", _jsx(_components.p, {
      children: "If approved, your review will appear in its entirety. We will not edit any content."
    }), "\n", _jsx(_components.p, {
      children: "These Terms of Use govern your conduct associated with the Customer Ratings & Review (the \"CRR\") and the Question & Answer (the \"Q&A\") forums offered by Checkayou as a free service to users who agree to abide by the following terms and conditions. To the extent of any conflict between Checkayou's Privacy Policy and these Terms of Use, these Terms of Use shall prevail with respect to the CRR or Q&A forums."
    }), "\n", _jsx(_components.h3, {
      children: "1. Representation & Warranties"
    }), "\n", _jsx(_components.p, {
      children: "By submitting any content to Checkayou, you represent and warrant that:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "you are the sole author and owner of the intellectual property rights thereto;"
      }), "\n", _jsx(_components.li, {
        children: "all \"moral rights\" that you may have in such content have been voluntarily waived by you;"
      }), "\n", _jsx(_components.li, {
        children: "all content that you post is accurate;"
      }), "\n", _jsx(_components.li, {
        children: "you are at least 13 years old; and that"
      }), "\n", _jsx(_components.li, {
        children: "public use of the content you supply on these forums does not violate these Terms of Use and will not cause injury to any person or entity."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "You further agree and warrant that you shall not submit any content:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "that is known by you to be false, inaccurate or misleading;"
      }), "\n", _jsx(_components.li, {
        children: "that infringes any third party's copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;"
      }), "\n", _jsx(_components.li, {
        children: "that violates any law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising);"
      }), "\n", _jsx(_components.li, {
        children: "that is, or may reasonably be considered to be, inaccurate, abusive, vulgar, indecent, defamatory, libelous, hateful, racially or religiously biased or offensive, threatening or harassing to any individual, partnership or corporation;"
      }), "\n", _jsx(_components.li, {
        children: "for which you were compensated or granted any consideration by any third party;"
      }), "\n", _jsx(_components.li, {
        children: "that includes any information that references other websites, addresses, email addresses, contact information or phone numbers or any material that has been copied from another individual or entity; or"
      }), "\n", _jsx(_components.li, {
        children: "that contains any computer viruses, worms or other potentially damaging computer programs or files."
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      children: "2. Intellectual Property"
    }), "\n", _jsx(_components.p, {
      children: "Any content that you submit to Checkayou, either directly or through its third party service providers, you acknowledge and agree that Checkayou shall own all right, title and interest in and to such content, including all modifications, improvements, upgrades, derivative works, and feedback related thereto and all intellectual property rights therein. To the extent applicable, you agree to assign to Checkayou a perpetual, irrevocable, royalty-free, and transferable right and license to use, copy, modify, delete in its entirety, adapt, publish, translate, and/or create derivative works from, sell, distribute, and/or incorporate such content into any form, medium or technology throughout the world without compensation to you."
    }), "\n", _jsx(_components.h3, {
      children: "3. Indemnity & Liability"
    }), "\n", _jsx(_components.p, {
      children: "You agree to indemnify and hold Checkayou (and its officers, directors, agents, subsidiaries, joint ventures, employees, representatives, and third-party service providers), harmless from all claims, demands, and damages (actual and consequential) of any kind and nature, known and unknown, including reasonable legal fees, arising out of a breach of your representations and warranties set forth above, or your violation of any law or the rights of any third party. In no event will Checkayou be liable to any party for any direct, indirect, special or other consequential damages for any use of the CRR and Q&A forums, including forum participants following tips and/or advice posted on these forums."
    }), "\n", _jsx(_components.p, {
      children: "Information on these forums may contain inaccuracies or typographical errors. Checkayou is not responsible for any postings on the CRR and Q&A forums nor does Checkayou guarantee the accuracy, reliability or completeness of any information provided in these forums. Forum participants are solely responsible for their use of the forums. While Checkayou will make reasonable efforts to monitor the CRR and Q&A forums, it cannot effectively monitor all material posted and, therefore, makes no representation or warranty with respect thereto and assumes no liability for incorrect product information, mistakes, misinformation, falsehoods, profanity, obscenity, or other inappropriate material posted by any forum participant. Information on the CRR and Q&A forums is provided AS-IS without warranty of any kind, either express or implied."
    }), "\n", _jsx(_components.h3, {
      children: "4. General"
    }), "\n", _jsx(_components.p, {
      children: "The CRR and Q&A forums are a public forum. Messages, questions, tips, advice and answers posted here can be viewed by the public and reflect solely the opinions of the forum participants, including without limitation, Checkayou.com product suppliers, and in no way reflect the opinion of or are endorsed by Checkayou. You therefore acknowledge that you, not Checkayou, are responsible for the contents of your submission. None of the content that you submit shall be subject to any obligation of confidence on the part of Checkayou, its agents, subsidiaries, affiliates, partners or third party service providers and their respective directors, officers and employees."
    }), "\n", _jsx(_components.p, {
      children: "Ratings and reviews and questions and answers are generally posted within two to four business days. Checkayou reserves the right, at its sole discretion and without notice, to modify, condense or delete any content on the CRR and Q&A forums that Checkayou deems, at its sole discretion, to violate the content guidelines or any other provision of these Terms of Use. Checkayou does not guarantee that you will have any recourse through Checkayou to edit or delete any content you have submitted."
    }), "\n", _jsx(_components.p, {
      children: "By submitting your email address in connection with your rating and review and/or question and answer, you agree that Checkayou and its third party service providers may use your e-mail address to contact you about the status of your rating and review, question and answer, and for other administrative purposes."
    }), "\n", _jsx(_components.p, {
      children: "By use of the CRR and Q&A forums, forum participants hereby expressly acknowledge and agree to the foregoing terms and conditions."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
