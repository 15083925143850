import React from "react";
import { styled } from "goober";
import { tdt } from "i18n";
import { Link } from "shared/Link";
import { utils } from "theme";

const Styled = {
  Nav: styled("div")`
    &:not(:focus-within) {
      ${utils.srOnly}
    }

    width: 100%;
    height: var(--sp-5);

    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};

export const SkipToNav: React.FC = () => (
  <Styled.Nav aria-label={tdt("Skip Link")}>
    <ul>
      <li>
        <Link to={{ hash: "#main" }} size="small">
          {tdt("Skip to Content")}
        </Link>
      </li>
    </ul>
  </Styled.Nav>
);
