import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Rollbar from "rollbar";
import { Provider as RollbarContext, ErrorBoundary } from "@rollbar/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider, AuthedWrapper } from "shared/hooks/use-auth";
import { DesktopWrapper } from "pages/DesktopWrapper";
import { GlobalStyles } from "theme";
import { Landing } from "pages/Landing";
import { CreateReview } from "pages/CreateReview";
import { AboutUs } from "pages/AboutUs";
import { TermsAndConditions } from "pages/TermsAndConditions";
import { Privacy } from "pages/Privacy";
import { ContactUs } from "pages/ContactUs";
import { Dashboard } from "pages/Dashbaord";
import { EditReview } from "pages/EditReview";
import { SearchResults } from "pages/SearchResults";
import { Review } from "pages/Review";
import { VerificationFlow } from "pages/VerificationFlow";
import { Profile } from "pages/Profile";
import { EditProfile } from "pages/EditProfile";

const NotFound = () => <div>404</div>;

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
  capture_ip: false,
};
const rollbar = new Rollbar(rollbarConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: (error) => rollbar.error("Fetching error", error),
    },
    mutations: {
      onError: (error) => rollbar.error("Fetching error", error),
    },
  },
});

// TODO split routes into separate file
export const App = () => {
  return (
    <React.StrictMode>
      <RollbarContext instance={rollbar}>
        <ErrorBoundary fallbackUI={ErrorFallback}>
          <GlobalStyles />
          <BrowserRouter>
            <AuthProvider>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route path="/" element={<DesktopWrapper />}>
                    <Route index element={<Landing />} />
                    <Route path="about" element={<AboutUs />} />
                    <Route
                      path="terms-and-conditions"
                      element={<TermsAndConditions />}
                    />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="search" element={<SearchResults />} />
                    <Route path="review/:id" element={<Review />} />
                    <Route path="profile/:id" element={<Profile />} />

                    <Route path="/reviewer" element={<AuthedWrapper />}>
                      <Route
                        index
                        element={<Navigate replace to="dashboard" />}
                      />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="profile" element={<EditProfile />} />
                      <Route path="review">
                        <Route
                          index
                          element={<Navigate replace to="create" />}
                        />
                        <Route path="create" element={<CreateReview />} />
                        <Route path=":id" element={<EditReview />} />
                      </Route>
                      <Route path="verify" element={<VerificationFlow />} />
                    </Route>
                  </Route>
                  <Route path="*" element={<DesktopWrapper />}>
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Routes>
              </QueryClientProvider>
            </AuthProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </RollbarContext>
    </React.StrictMode>
  );
};

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
