import React, { useId } from "react";
import { css, styled } from "goober";
import SearchIcon from "theme/inline-assets/search-icon.svg";
import { cnc } from "theme";

const Styled: any = {
  inputClass: css`
    width: 100%;
    color: var(--text-primary);

    &::placeholder {
      color: var(--text-placeholder);
    }
  `,
};

Styled.InputWrapper = styled("div")`
  border-radius: 24px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: var(--background-card);

  &:where([data-size="medium"]) {
    --font-size: var(--sp-6);
    --line-height: var(--sp-6);
    --font-weight: 400;
    gap: var(--sp-4);
    padding: var(--sp-3) var(--sp-7);
  }

  &:where([data-size="large"]) {
    --font-size: var(--sp-6);
    --line-height: var(--sp-8);
    --font-weight: 600;
    gap: var(--sp-4);
    padding: var(--sp-6) var(--sp-7);
  }

  line-height: var(--line-height);
  font-size: var(--line-height);
  font-weight: var(--font-weight);

  ${Styled.inputClass} {
    line-height: var(--line-height);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
  }
`;

interface BaseProps {
  size?: "large" | "medium" | "small";
  className?: string;
  children?: React.ReactNode;
}

type Props = BaseProps & Omit<React.ComponentPropsWithRef<"input">, "size">;

export const Input = React.forwardRef<HTMLInputElement, Props>(function Input(
  {
    size,
    className,
    children,
    placeholder,
    "aria-labelledby": labelledBy,
    ...props
  },
  ref
) {
  const placeholderId = `hint-id-${useId()}`;
  return (
    <Styled.InputWrapper data-size={size} className={className}>
      <input
        ref={ref}
        className={Styled.inputClass}
        type="text"
        placeholder={placeholder}
        aria-labelledby={cnc(labelledBy, placeholder && placeholderId)}
        {...props}
      />
      {children}
      {placeholder && <span className="sr-only" id={placeholderId}></span>}
    </Styled.InputWrapper>
  );
}) as React.ForwardRefExoticComponent<Props> & {
  Input: string;
  Wrapper: string;
};

Input.Wrapper = Styled.InputWrapper;
Input.Input = `.${Styled.inputClass}`;

// TODO make this polymorphic & reuse code
type TextAreaProps = BaseProps &
  Omit<React.ComponentPropsWithRef<"textarea">, "size">;
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(
    {
      size,
      className,
      children,
      placeholder,
      "aria-labelledby": labelledBy,
      ...props
    },
    ref
  ) {
    const placeholderId = `hint-id-${useId()}`;
    return (
      <Styled.InputWrapper data-size={size} className={className}>
        <textarea
          ref={ref}
          className={Styled.inputClass}
          placeholder={placeholder}
          aria-labelledby={cnc(labelledBy, placeholder && placeholderId)}
          {...props}
        />
        {children}
        {placeholder && <span className="sr-only" id={placeholderId}></span>}
      </Styled.InputWrapper>
    );
  }
);
