// https://github.com/springload/react-accessible-accordion
import React from "react";
import { css } from "goober";
import {
  Accordion as BaseAccordion,
  AccordionItem as BaseAccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { cnc } from "theme";
import ChevronIcon from "theme/inline-assets/chevron-down-icon.svg";

const headerClass = "accordion_header";
const buttonClass = "accordion_button";
const panelClass = "accordion_panel";
const iconClass = "accordion_icon";
const Styled = {
  accordionClass: css`
    & > *:not(:last-child) {
      .${buttonClass}, .${panelClass} {
        border-bottom: 1px solid var(--divider);

        &[aria-expanded="true"] {
          border: none;
        }
      }
    }

    .${buttonClass} {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--sp-3);

      padding: var(--sp-7) var(--sp-10);

      &[aria-expanded="true"] {
        .${iconClass} {
          transform: rotate(180deg);
        }
      }
    }

    .${iconClass} {
      min-width: var(--sp-6);
      width: var(--sp-6);
      height: var(--sp-6);
      transition: 200ms transform ease-in-out;
    }

    .${panelClass} {
      padding: var(--sp-7);
    }
  `,
};

interface Props {
  allowMultipleExpanded?: boolean;
  allowZeroExpanded?: boolean;
  preExpanded?: string[];
  className?: string;
  children: React.ReactNode;
}

interface ItemProps {
  id?: string;
  children: React.ReactNode;
}

interface HeadingProps {
  "aria-level"?: number;
  children: React.ReactNode;
}

interface PanelProps {
  children: React.ReactNode;
}

type AccordionFC = React.FC<Props> & {
  Item: React.FC<ItemProps>;
  Heading: React.FC<HeadingProps>;
  Panel: React.FC<PanelProps>;
};

export const Accordion: AccordionFC = ({
  allowMultipleExpanded = true,
  allowZeroExpanded = true,
  preExpanded = [],
  className,
  children,
}) => {
  return (
    <BaseAccordion
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded={allowZeroExpanded}
      preExpanded={preExpanded}
      className={cnc(Styled.accordionClass, className)}
    >
      {children}
    </BaseAccordion>
  );
};

const AccordionItem: React.FC<ItemProps> = ({ id, children }) => (
  <BaseAccordionItem uuid={id}>{children}</BaseAccordionItem>
);

// TODO use filter children for icon
const AccordionHeading: React.FC<HeadingProps> = ({
  "aria-level": ariaLevel,
  children,
}) => (
  <AccordionItemHeading
    aria-level={ariaLevel}
    className={cnc("h5", headerClass)}
  >
    <AccordionItemButton className={buttonClass}>
      {children}
      <ChevronIcon aria-hidden className={iconClass} />
    </AccordionItemButton>
  </AccordionItemHeading>
);

const AccordionPanel: React.FC<PanelProps> = ({ children }) => (
  <AccordionItemPanel className={panelClass}>{children}</AccordionItemPanel>
);

Accordion.Item = AccordionItem;
Accordion.Heading = AccordionHeading;
Accordion.Panel = AccordionPanel;
