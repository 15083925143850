import React, { useState } from "react";
import { styled } from "goober";
import { utils } from "theme";
import { tdt } from "i18n";
import { useForm } from "react-hook-form";
import { Input, TextArea } from "shared/Input";
import { useAuth, useAuthPromise, useUser } from "shared/hooks/use-auth";
import Persona from "persona";
import { LoadingSpinner, PageLoadingSpinner } from "shared/LoadingSpinner";
import { Navigate } from "react-router";
import { Card } from "shared/Card";
import { Button } from "shared/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ImageUploadingModule from "react-images-uploading";
import AddIcon from "theme/inline-assets/add-icon.svg";
import { ProfileForm } from "pages/EditProfile";

const ImageUploading =
  ImageUploadingModule.default as typeof ImageUploadingModule;

const imgTypes = ["jpg", "jpeg", "png", "svg", "webp"];
const imgMaxSize = 1_000_000;

const Styled = {
  Container: styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .title {
      width: 100%;

      padding: var(--sp-9);
      background-color: var(--primary-main);
      color: var(--primary-contrast-text);

      text-align: center;
    }

    & > .content {
      margin: 0 auto;
      max-width: 1440px;

      padding: var(--sp-10);

      & .verification-card {
        width: 600px;
        height: 800px;

        & iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  `,
};

// TODO: use swr?
// TODO move to api
const putVerificationStatus = (token: string) => async (data: any) => {
  const response = await fetch(`/api/reviewer/verify`, {
    method: "PUT",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(data),
  });

  const responseData = response.json();
  return responseData;
};

const postVerificationStatus = (token: string) => async (data: any) => {
  const response = await fetch(`/api/reviewer/verify`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(data),
  });

  const responseData = response.json();
  return responseData;
};

const getVerificationStatus = (token: string) => async () => {
  const response = await fetch(`/api/reviewer/verify`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = response.json();
  return data;
};

export const VerificationFlow: React.FC = () => {
  return (
    <Styled.Container>
      <h2 className="title">{tdt("Verification")}</h2>
      <div className="content">
        <VerificationView />
      </div>
    </Styled.Container>
  );
};

const VerificationView: React.FC = () => {
  const { user } = useUser();
  const token = useAuth();

  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    [`/api/reviewer/verify`, { token }],
    getVerificationStatus(token!),
    {
      enabled: !!token,
    }
  );
  const { mutate, isLoading: isSubmitting } = useMutation(
    postVerificationStatus(token!),
    {
      onSuccess(data) {
        queryClient.setQueryData([`/api/reviewer/verify`, { token }], data);
      },
    }
  );

  const { mutate: onComplete, isLoading: isSubmittingCompleted } = useMutation(
    putVerificationStatus(token!),
    {
      onSuccess(data) {
        queryClient.setQueryData([`/api/reviewer/verify`, { token }], data);
      },
    }
  );

  if (!user || !token || isLoading || isSubmittingCompleted)
    return <PageLoadingSpinner />;
  if (user.attrs.verified === "complete")
    return <Navigate replace to="/reviewer/dashboard" />;

  switch (data.verificationStatus) {
    case "created": {
      return (
        <ProfileForm onSubmit={mutate} isLoading={isSubmitting}>
          <ProfileSubmit />
        </ProfileForm>
      );
    }
    case "in-progress":
    case "verification-started":
    case "verification-processing": {
      return <PersonaForm onSubmit={onComplete} />;
    }
    case "submitted": {
      return (
        <Card>
          <h3>{tdt("Verification Submitted")}</h3>
          <p className="body1">
            {tdt("The standard verification takes 3-5 min. Check back soon!")}
          </p>
        </Card>
      );
    }
    case "verification-failed": {
      return (
        <Card>
          <h3>{tdt("There was an problem")}</h3>
          <p className="body1">
            {tdt(
              "We are working on resolving it. For more info please email verifications@checkayou.com"
            )}
          </p>
        </Card>
      );
    }
    case "verification-approved": {
      return <Navigate replace to="/reviewer/dashboard" />;
    }
  }

  return null;
};

const PersonaForm: React.FC = ({ onSubmit }) => {
  const { user } = useUser();
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return (
    <Card className="verification-card">
      {!isLoaded && <LoadingSpinner />}
      <Persona.Inquiry
        templateId={process.env.PERSONA_TEMPLATE_ID}
        environment={process.env.PERSONA_ENVIRONMENT}
        onComplete={onSubmit}
        frameWidth="100%"
        frameHeight="100%"
        referenceId={user!.sub}
        onReady={() => {
          setIsLoaded(true);
        }}
      />
    </Card>
  );
};

const ProfileSubmit: React.FC<{
  onSubmit?(data: any): void;
  isFormLoading?: boolean;
}> = ({ onSubmit, isFormLoading }) => (
  <Button
    type="button"
    disabled={isFormLoading}
    onClick={onSubmit}
    variant="outline"
    color="primary"
    size="medium"
  >
    {isFormLoading ? <LoadingSpinner /> : tdt("Next")}
  </Button>
);

// interface FormProps {
//   onSubmit(data: any): void;
// }
// const VerificationForm: React.FC<FormProps> = ({ onSubmit }) => {
//   return (
//     <Styled.FormContainer>
//       <UserInfoForm onSubmit={() => {}} />
//       <BusinessEmailVerification onSubmit={() => {}} />
//     </Styled.FormContainer>
//   );
// };

// interface UserFormProps {
//   onSubmit(data: any): void;
// }
// const UserInfoForm: React.FC<UserFormProps> = ({ onSubmit }) => {
//   const { register, handleSubmit } = useForm();

//   return (
//     <form>
//       <label id="name-lbl" htmlFor="name" className="h5">
//         {tdt("Username")}
//       </label>
//       <Input
//         {...register("displayName")}
//         id="name"
//         aria-labelledby="name-lbl"
//         placeholder={tdt("The name others will see on your reviews.")}
//         size="medium"
//         autoComplete="off"
//       />
//     </form>
//   );
// };

// interface BusinessEmailFormProps {
//   onSubmit(data: any): void;
// }
// const BusinessEmailVerification: React.FC<BusinessEmailFormProps> = ({
//   onSubmit,
// }) => {
//   const { register, handleSubmit } = useForm();

//   return (
//     <form>
//       <p className="body1">
//         {tdt("You will receive an email with a verification link.")}
//       </p>
//       <label id="business-email-lbl" htmlFor="business-email" className="h5">
//         {tdt("Business Email")}
//       </label>
//       <Input
//         {...register("businessEmail")}
//         id="business-email"
//         aria-labelledby="business-email-lbl"
//         placeholder={tdt("you@yourwork.com")}
//         size="medium"
//       />
//     </form>
//   );
// };

// interface PhoneFormProps {
//   onSubmit(data: any): void;
// }
// const PhoneVerification: React.FC<PhoneFormProps> = ({ onSubmit }) => {
//   const { register, handleSubmit } = useForm();

//   return (
//     <form>
//       <p className="body1">
//         {tdt(
//           "You will receive an text with a challenge question. Standard messaging rates apply."
//         )}
//       </p>
//       <label id="phone-lbl" htmlFor="phone" className="h5">
//         {tdt("Phone Number")}
//       </label>
//       <Input
//         {...register("phone")}
//         id="phone"
//         type="tel"
//         aria-labelledby="phone-lbl"
//         placeholder={tdt("123-456-7890")}
//         size="medium"
//       />
//     </form>
//   );
// };
