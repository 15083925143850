import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "goober";
import algoliasearch from "algoliasearch/lite";
import type { AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import { tdt } from "i18n";
import { Input } from "shared/Input";
import SearchIcon from "theme/inline-assets/search-icon.svg";
import CloseIcon from "theme/inline-assets/close-icon.svg";
import { useQuery } from "@tanstack/react-query";

const Styled = {
  Autocomplete: styled("div")`
    width: 100%;
    position: relative;

    &[aria-expanded="true"] {
      ${Input.Wrapper} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    & .panel {
      border-top: 1px solid var(--divider);
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      position: absolute;
      top: 100%;

      width: 100%;

      padding: var(--sp-6) 0;
      background-color: var(--background-card);

      & ul {
        display: flex;
        flex-direction: column;

        & li {
          cursor: pointer;
          padding: var(--sp-1) var(--sp-6);

          &:hover {
            background-color: var(--background-default);
          }
        }
      }
    }
  `,
};

const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_SEARCH_KEY
);
const index = searchClient.initIndex("cay-reviews");

/*
const getReview =
  (token: string) =>
  async ({ queryKey: [, { id }] }) => {
    const response = await fetch(`/api/reviewer/review/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = response.json();
    return data;
  };
["api/reviewer/review", { id: params.id, token }],
    getReview(token!),
    {
      enabled: !!token,
    }
*/
export interface SearchResult extends BaseItem {
  objectID: string;
  reviewTitle: string;
  creatorName: string;
  created: string;
  rating: string;
  review: string;
}

const querySearch = ({ queryKey: [, { query, page, pageSize }] }) =>
  index.search<SearchResult>(query, {
    page,
    hitsPerPage: pageSize,
  });
// searchClient.search<SearchResult>([
//   {
//     indexName: "cay-reviews",
//     query,
//     params: { hitsPerPage: pageSize, page },
//   },
// ]);
export function useSearchResults({ query, page, pageSize }) {
  return useQuery(["search-results", { query, page, pageSize }], querySearch);
}

export function SearchBar({
  size,
  onSubmit,
  initialValue,
}: {
  size: "medium" | "large";
  onSubmit: (q: string) => void;
  initialValue?: string;
}) {
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<SearchResult>
  >({ query: initialValue } as AutocompleteState<SearchResult>);

  const formRef = useRef(null);
  const inputRef = useRef(null);

  const [autocomplete] = useState(
    createAutocomplete<SearchResult>({
      onStateChange({ state }) {
        setAutocompleteState(state);
      },
      getSources<SearchResult>() {
        return [
          {
            sourceId: "reviews",
            getItemInputValue({ item }) {
              return item.query;
            },
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "cay-reviews",
                    query,
                    params: {
                      hitsPerPage: 6,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                      attributesToRetrieve: ["reviewTitle"],
                    },
                  },
                ],
              });
            },
            onSelect({ item, setQuery, setIsOpen }) {
              setQuery(item.reviewTitle);
              setIsOpen(false);
              setTimeout(() => {
                formRef.current.requestSubmit();
              }, 0);
            },
          },
        ];
      },
    })
  );

  useEffect(() => {
    if (initialValue) autocomplete.setQuery(initialValue);
  }, []);

  const onBlur = () => {
    autocomplete.setIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = e.target[0].value ?? "";
    autocomplete.setIsOpen(false);
    onSubmit(query);
  };

  return (
    <Styled.Autocomplete {...autocomplete.getRootProps({})}>
      <form
        ref={formRef}
        {...autocomplete.getFormProps({
          inputElement: inputRef.current,
          onSubmit: handleSubmit,
        })}
      >
        <Input
          ref={inputRef}
          size={size}
          {...autocomplete.getInputProps({
            inputElement: inputRef.current,
            maxLength: 30,
            placeholder: tdt("Search"),
            onBlur,
          })}
        >
          {!autocompleteState.query ? (
            <SearchIcon />
          ) : (
            <button type="reset">
              <CloseIcon />
            </button>
          )}
        </Input>
        {autocompleteState.isOpen && (
          <div className="panel" {...autocomplete.getPanelProps({})}>
            {autocompleteState.collections.map((collection, i) => {
              const { source, items } = collection;

              return (
                <div key={`source-${i}`} className="source">
                  {!!items.length && (
                    <ul {...autocomplete.getListProps()}>
                      {items.map((item) => (
                        <li
                          key={item.objectID}
                          {...autocomplete.getItemProps({
                            item,
                            source,
                          })}
                        >
                          {item.reviewTitle}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </form>
    </Styled.Autocomplete>
  );
}
