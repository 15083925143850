import { createGlobalStyles } from "goober/global";

const spaceUnit = 0.25;
const space: Record<string, string> = {};
for (let i = 0; i < 25; ++i) {
  space[`sp-${i}`] = `calc(var(--unit) * ${i}rem)`;
  space[`sp-${i}_5`] = `calc(var(--unit) * ${i + 0.5}rem)`;
}

const colors = {
  blue: {
    dark: "#3060AC",
    medium: "#3883F3",
    light: "#95B3EE",
  },
  red: {
    dark: "#B8343F",
    medium: "#F05D5E",
    light: "#F6A2A2",
  },
  gray: {
    dark: "#333333",
    medium: "#525252",
    light: "#E7E7E7",
    lightest: "#FFFFFF",
  },
};

const theme = {
  background: {
    default: colors.gray.light,
    card: colors.gray.lightest,
    footer: colors.gray.dark,
  },
  text: {
    primary: colors.gray.dark,
    secondary: colors.gray.medium,
    disabled: colors.gray.medium,
    hint: colors.gray.medium,
    contrast: colors.gray.lightest,
  },
  primary: {
    main: colors.blue.medium,
    light: colors.blue.light,
    dark: colors.blue.dark,
    contrastText: colors.gray.lightest,
  },
  secondary: {
    main: colors.red.medium,
    light: colors.red.light,
    dark: colors.red.dark,
    contrastText: colors.gray.lightest,
  },
  // error: {
  //   main: "",
  //   light: "",
  //   dark: "",
  //   contrastText: "",
  // },
  // warning: {
  //   main: "",
  //   light: "",
  //   dark: "",
  //   contrastText: "",
  // },
  // info: {
  //   main: "",
  //   light: "",
  //   dark: "",
  //   contrastText: "",
  // },
  // success: {
  //   main: "",
  //   light: "",
  //   dark: "",
  //   contrastText: "",
  // },
  divider: colors.gray.medium,
  // button: {
  //   main: "",
  //   light: "",
  //   dark: "",
  //   text: "",
  // },
};

/*
  X-Small   None  <576px
  Small     sm    ≥576px
  Medium    md    ≥768px
  Large     lg    ≥992px
  X large   xl    ≥1200px
  XX large  xxl   ≥1400px
*/

const breakpoints = {
  sm: "576",
  md: "768",
  lg: "992",
  xl: "1200",
  xxl: "1400",
};
for (const [key, value] of Object.entries(breakpoints)) {
  breakpoints[key] = `@media (min-width: ${value}px)`;
}

export const utils = {
  srOnly: `
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  `,
  headerHeight: "var(--header-height)",
  containerWidth: "var(--container-width)",
  ...breakpoints,
};

export function cnc(...args: (string | false | null | undefined)[]) {
  return args.filter(Boolean).join(" ");
}

export const GlobalStyles = createGlobalStyles`
  :root {
    /* Create global vars */
    --unit: ${spaceUnit};
    ${flattenVarMap(space)}

    /* Themes */
    ${flattenVarMap(theme)}

    /* Header */
    --header-height: 80px;
    --container-width: 1440px;
  }

  *:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
    all: unset;
    display: revert;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    min-width: 0;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  a,
  button {
    cursor: pointer;
  }

  ol,
  ul,
  menu {
    list-style: none;
  }

  table {
    border-collapse: collapse;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    -webkit-user-select: auto;
  }

  textarea {
    white-space: revert;
  }

  meter {
    -webkit-appearance: revert;
    appearance: revert;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    height: 100%;
  }

  .wrapper {
    position: relative;
    overflow-x: hidden;
  }

  :where([hidden]) {
    display: none;
  }

  :where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
  }

  :where([draggable="true"]) {
    -webkit-user-drag: element;
  }

  :where(h1, .h1) {
    font-size: var(--sp-10);
    line-height: var(--sp-10);
    font-weight: 600;
  }

  :where(h2, .h2) {
    font-size: var(--sp-8);
    line-height: var(--sp-8);
    font-weight: 600;
  }

  :where(h3, .h3) {
    font-size: var(--sp-6);
    line-height: var(--sp-6);
    font-weight: 600;
  }

  :where(h4, .h4) {
    font-size: var(--sp-4);
    line-height: var(--sp-4);
    font-weight: 600;
  }

  :where(h5, .h5) {
    font-size: var(--sp-4);
    line-height: var(--sp-4);
    font-weight: 400;
  }

  :where(h6, .h6) {
    font-size: var(--sp-4);
    line-height: var(--sp-4);
    font-weight: 400;
  }

  :where(.body1) {
    font-size: var(--sp-4);
    line-height: var(--sp-5);
    font-weight: 400;
  }

  :where(hr, .hr) {
    border: none;
    width: 100%;

    height: var(--sp-0_5);
    background-color: var(--divider);
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  /* josefin-sans-regular - latin */
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('/assets/fonts/josefin-sans-v25-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/assets/fonts/josefin-sans-v25-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  #check-a-root {
    isolation: isolate;
    font-size: 16px;
    font-family: "Josefin Sans", Arial, sans-serif;
    font-weight: 500;
    line-height: 1.5;
    color: ${theme.text.primary};
  }

  .sr-only {
    ${utils.srOnly}
  }

  ${themeToClass(theme)}
`;

function flattenVarMap<T extends Record<string, any>>(
  colors: T,
  name: string[] = []
) {
  const colorVars: string[] = [];
  for (const [key, value] of Object.entries(colors)) {
    const newName = [...name, key];
    if (typeof value === "string") {
      colorVars.push(`${varize(newName)}: ${value};`);
    } else {
      colorVars.push(flattenVarMap(value, newName));
    }
  }

  return colorVars.join("\n");
}

function varize(nameList: (string | number)[]) {
  return `--${nameList.map(toKebabCase).join("-")}`;
}

function toKebabCase(val: string | number) {
  if (typeof val !== "string") return val;
  return val
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s]+/g, "-")
    .toLowerCase();
}

function themeToClass<T extends Record<string, any>>(
  theme: T,
  name: string[] = []
) {
  const themeClasses: string[] = [];
  for (const [key, value] of Object.entries(theme)) {
    const newName = [...name, key];
    if (typeof value === "string") {
      themeClasses.push(
        `.${toKebabCase(newName.join("-"))} { color: var(${varize(newName)}); }`
      );
    } else {
      themeClasses.push(themeToClass(value, newName));
    }
  }

  return themeClasses.join("\n");
}
