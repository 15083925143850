import React, { useState } from "react";
import { styled } from "goober";
import { useAuth, useAuthPromise } from "shared/hooks/use-auth";
import { useForm } from "react-hook-form";
import { Card } from "shared/Card";
import { Input, TextArea } from "shared/Input";
import { tdt } from "i18n";
import ImageUploadingModule from "react-images-uploading";
import AddIcon from "theme/inline-assets/add-icon.svg";
import { Button } from "shared/Button";
import { LoadingSpinner, PageLoadingSpinner } from "shared/LoadingSpinner";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
const ImageUploading =
  ImageUploadingModule.default as typeof ImageUploadingModule;

const Styled = {
  Container: styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .title {
      width: 100%;

      padding: var(--sp-9);
      background-color: var(--primary-main);
      color: var(--primary-contrast-text);

      text-align: center;
    }

    & > .content {
      margin: 0 auto;
      max-width: 1440px;

      padding: var(--sp-10);
    }
  `,
  ProfileForm: styled("form")`
    display: flex;
    flex-direction: column;
    gap: var(--sp-4);

    & .avatar {
      margin: 0 auto;
      border: 2px dashed var(--divider);
      border-radius: 50%;
      width: var(--sp-22);
      height: var(--sp-22);

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      & > button {
        position: absolute;
        inset: 0;
      }

      & > img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  `,
};

// TODO: use swr?
// TODO move to api
const putProfile = (token: string) => async (profile: any) => {
  const response = await fetch(`/api/reviewer/profile`, {
    method: "PUT",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(profile),
  });

  const data = response.json();
  return data;
};

const getProfile = (token: string) => async () => {
  const response = await fetch(`/api/reviewer/profile`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = response.json();
  return data;
};

export const EditProfile: React.FC = () => {
  const token = useAuth();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ["api/reviewer/profile", { token }],
    getProfile(token!),
    {
      enabled: !!token,
    }
  );
  const { mutate, isLoading: isSubmitting } = useMutation(putProfile(token!), {
    onSuccess() {
      navigate(`/reviewer`);
    },
  });

  if (!token || isLoading) return <PageLoadingSpinner />;
  return (
    <Styled.Container>
      <h2 className="title">{tdt("Edit Profile")}</h2>
      <div className="content">
        <ProfileForm
          initialData={data}
          onSubmit={mutate}
          isLoading={isSubmitting}
        >
          <ProfileSubmit />
        </ProfileForm>
      </div>
    </Styled.Container>
  );
};

const ProfileSubmit: React.FC<{
  onSubmit?(data: any): void;
  isFormLoading?: boolean;
}> = ({ onSubmit, isFormLoading }) => (
  <Button
    type="button"
    disabled={isFormLoading}
    onClick={onSubmit}
    variant="outline"
    color="primary"
    size="medium"
  >
    {isFormLoading ? <LoadingSpinner /> : tdt("Save")}
  </Button>
);

const imgTypes = ["jpg", "jpeg", "png", "svg", "webp"];
const imgMaxSize = 10_000_000;

interface FormProps {
  initialData?: any;
  onSubmit(data: any): any;
  isLoading: boolean;
  children: React.ReactNode;
}

async function uploadImage(tokenPromise: Promise<string>, file: File) {
  if (!file) return "";

  const token = await tokenPromise;
  const response = await fetch("/api/reviewer/review/image", {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { id, uploadURL } = await response.json();

  const body = new FormData();
  body.append("file", file.file);

  await fetch(uploadURL, {
    body,
    method: "POST",
  });

  return id;
}

export const ProfileForm: React.FC<FormProps> = ({
  isLoading,
  onSubmit,
  initialData,
  children,
}) => {
  const token = useAuthPromise();
  const { register, handleSubmit } = useForm({ defaultValues: initialData });
  const [images, setImages] = useState([]);
  const [imgLoading, setImgLoading] = useState(false);
  const onFormSubmit = async () => {
    setImgLoading(true);
    const fileId =
      initialData?.avatarId ?? (await uploadImage(token, images[0]));
    setImgLoading(false);
    handleSubmit((data) => {
      console.log({ data });
      onSubmit({ ...data, avatarId: fileId });
    })();
  };

  isLoading = isLoading || imgLoading;
  return (
    <Card>
      <Styled.ProfileForm onSubmit={() => {}}>
        <div className="input-group">
          <label id="name-lbl" htmlFor="name" className="h5">
            {tdt("Username")}
          </label>
          <Input
            {...register("displayName")}
            id="name"
            aria-labelledby="name-lbl"
            size="medium"
            autoComplete="off"
            disabled={isLoading}
          />
        </div>
        <div className="input-group">
          <label id="bio-lbl" htmlFor="bio" className="h5">
            {tdt("Bio")}
          </label>
          <TextArea
            {...register("bio")}
            id="bio"
            aria-labelledby="bio-lbl"
            autoComplete="off"
            size="medium"
            disabled={isLoading}
          />
        </div>

        {/* TODO: allow updating avatar */}
        {!initialData?.avatarId && (
          <div className="input-group">
            <label htmlFor="imgs" className="h5">
              {tdt("Avatar")}
            </label>
            <ImageUploading
              multiple={false}
              value={images}
              onChange={setImages}
              acceptType={imgTypes}
              maxFileSize={imgMaxSize}
              inputProps={{
                id: "imgs",
                disabled: isLoading,
              }}
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                dragProps,
                errors,
              }) => (
                <>
                  <UploadErrors errors={errors} />
                  <div className="avatar">
                    <button
                      type="button"
                      className="drop-target"
                      onClick={
                        imageList.length
                          ? () => onImageUpdate(0)
                          : onImageUpload
                      }
                      {...dragProps}
                    >
                      <span className="sr-only">
                        {tdt("Drag and drop or click here")}
                      </span>
                    </button>
                    {imageList.length ? (
                      <img src={imageList[0].dataURL} />
                    ) : (
                      <AddIcon aria-hidden />
                    )}
                  </div>
                </>
              )}
            </ImageUploading>
          </div>
        )}

        {React.cloneElement(React.Children.only(children), {
          onSubmit: onFormSubmit,
          isFormLoading: isLoading,
        })}
      </Styled.ProfileForm>
    </Card>
  );
};

const UploadErrors: React.FC<{ errors: ErrorsType }> = ({ errors }) => {
  let errorText = null;
  if (errors?.acceptType)
    errorText = tdt("Selected file type is not supported.");
  else if (errors?.maxFileSize) errorText = tdt("Selected file exceeds 10mb.");

  return <>{errorText && <span className="upload-error">{errorText}</span>}</>;
};
