/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h4: "h4",
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    ol: "ol"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "What Is checkayou.com?"
    }), "\n", _jsx(_components.h4, {
      children: "The digital ratings platform that helps all customers make informed purchasing decisions, and assists businesses to gauge their reputation and effectiveness among customers"
    }), "\n", _jsx(_components.p, {
      children: "Checkayou.com is an online, digital platform that provides reviews of any product, service or business from authenticated users worldwide. We strive to provide the most in-depth reviews that are useful and accurate, and written by actual individuals (not bots). Reviews are a way for consumers to make informed purchasing decisions as well as gauge the reputation of a given company, and for businesses to gain an understanding of how customers view them."
    }), "\n", _jsx(_components.h3, {
      children: "About checkayou.com"
    }), "\n", _jsx(_components.p, {
      children: "Checkayou.com is an online Canadian-based company launched in 2022. Its goal is to provide helpful reviews for product creators, service providers, and other businesses to improve the overall customer experience. We strive to become the most effective online review source in North America with accurate and useful reviews."
    }), "\n", _jsx(_components.h3, {
      children: "How Does It Work?"
    }), "\n", _jsx(_components.p, {
      children: "Any Reviewer with a checkayou profile can review any product, service or business. Checkayou visitors can view the ratings to make informed decisions for their own purchase decisions. The platform uses an algorithm to summarize the most useful reviews for quick reference. Users can also view all reviews for free."
    }), "\n", _jsx(_components.h3, {
      children: "Creating a Profile and Writing Reviews"
    }), "\n", _jsx(_components.p, {
      children: "Sign up for a free profile!"
    }), "\n", _jsx(_components.p, {
      children: "Checkayou will use multiple verification methods to confirm individual profiles. Creating a profile can involve:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "A valid business email address (no free email accounts such as Google, Yahoo! or Hotmail accounts); and/or"
      }), "\n", _jsx(_components.li, {
        children: "Third-party verification; and/or"
      }), "\n", _jsx(_components.li, {
        children: "An invitation from a business that they frequented."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Once your profile is created and verified by Checkayou, you can sign in through a passwordless account and create reviews!"
    }), "\n", _jsx(_components.p, {
      children: "To write a review:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {
        children: "Search for the product, service or business;"
      }), "\n", _jsx(_components.li, {
        children: "Follow the Checkayou prompts; and"
      }), "\n", _jsx(_components.li, {
        children: "Provide your feedback."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "NOTE: Businesses are allowed to flag reviews they consider suspicious."
    }), "\n", _jsx(_components.h3, {
      children: "FAQs"
    }), "\n", _jsx(_components.h4, {
      children: "Why checkayou?"
    }), "\n", _jsx(_components.p, {
      children: "Checkayou was founded under the value of increasing integrity within the world. This value permeates everything we do and is the direct expression of its founders. Checkayou addresses the increasing need for a reliable, stable, and trustworthy place to counter online fraud. Like minded individuals are invited to join Checkayou to serve others in this important endeavor."
    }), "\n", _jsx(_components.h4, {
      children: "Who Can Write a Review on checkayou.com?"
    }), "\n", _jsx(_components.p, {
      children: "Anyone with an authenticated Profile can craft reviews."
    }), "\n", _jsx(_components.h4, {
      children: "How Much Does It Cost to Create a Profile?"
    }), "\n", _jsx(_components.p, {
      children: "Creating a Profile is free."
    }), "\n", _jsx(_components.h4, {
      children: "Are Checkayou.com Reviews Reliable?"
    }), "\n", _jsx(_components.p, {
      children: "Reviews are the sole opinion of the reviewer. As Users see reviews, they will be able to rate the usefulness of the reviews. Checkayou aims to provide a useful platform to help individual in the marketplace. Every user’s identity is verified to prevent fraud."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
