import React from "react";
import { styled } from "goober";
import { cnc, utils } from "theme";
import { useParams } from "react-router";
import { formatDate, tdt } from "i18n";
import { Card } from "shared/Card";
import { useQuery } from "@tanstack/react-query";
import { PageLoadingSpinner } from "shared/LoadingSpinner";
import { Stars } from "shared/Stars";
import Face1Icon from "theme/inline-assets/face-1-icon.svg";
import Face2Icon from "theme/inline-assets/face-2-icon.svg";
import Face3Icon from "theme/inline-assets/face-3-icon.svg";
import Face4Icon from "theme/inline-assets/face-4-icon.svg";
import Face5Icon from "theme/inline-assets/face-5-icon.svg";
import ChevronLeft from "theme/inline-assets/chevron-left-icon.svg";
import { Accordion } from "shared/Accordion";
import { Link } from "shared/Link";
import ModalImage from "react-modal-image";
import { useUser } from "shared/hooks/use-auth";
import AddIcon from "theme/inline-assets/add-icon.svg";

const Styled = {
  Container: styled("div")`
    margin: 0 auto;
    max-width: ${utils.containerWidth};
    width: 100%;

    padding: var(--sp-4) var(--sp-4) var(--sp-20);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-4);

    & > .bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .back-button {
      display: flex;
      align-items: center;
      svg {
        margin-bottom: 5px;
        min-width: 1em;
        width: 1em;
        height: 1em;
      }
    }

    & > .review-card {
      display: flex;
      flex-direction: column;
      gap: var(--sp-5);
    }

    & .header {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-areas:
        "date date date date"
        "title title title title"
        "rating author author author"
        "at-a-glance at-a-glance at-a-glance at-a-glance"
        "at-a-glance at-a-glance at-a-glance at-a-glance"
        "images images images images";
      grid-row-gap: var(--sp-4);

      @media (min-width: 992px) {
        grid-template-areas:
          "title title title date"
          "rating rating at-a-glance at-a-glance"
          "author author at-a-glance at-a-glance"
          "images images at-a-glance at-a-glance";
      }

      .title {
        grid-area: title;
        color: var(--primary-dark);
      }
      .rating {
        grid-area: rating;
        color: var(--secondary-dark);
        display: flex;
      }
      .date {
        grid-area: date;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: end;
      }
      .author {
        grid-area: author;
      }
      .images {
        grid-area: images;
        display: flex;
        flex-direction: column;
        gap: var(--sp-4);

        & .large-img {
          & .large-img {
            height: 150px;
          }
        }

        & .img-list {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: var(--sp-4);

          & .small-img {
            height: var(--sp-17);
            width: var(--sp-17);
          }
        }
      }

      .at-a-glance {
        grid-area: at-a-glance;

        & > li {
          border-bottom: 1px solid var(--divider);
          padding: var(--sp-3) var(--sp-6);
        }

        & .bool {
          display: grid;
          grid-template-columns: 2fr 1fr;

          & .response {
            text-align: end;
            color: var(--secondary-dark);
            &[data-response="true"] {
              color: var(--primary-dark);
            }
          }
        }

        & .list {
          display: grid;
          grid-template-columns: 1fr 3fr;

          & li {
            list-style: disc;

            &:not(:last-of-type) {
              margin-bottom: var(--sp-4);
            }
          }
        }

        & .link {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: var(--sp-4);

          white-space: nowrap;

          & a {
            color: var(--primary-main);

            display: block;
            max-width: 100%;

            white-space: break-spaces;
            overflow-wrap: break-word;
            word-wrap: break-word;

            word-break: break-all;
            word-break: break-word;
          }
        }
      }
    }

    & .review {
      border-bottom: 1px solid var(--divider);
      padding: var(--sp-6) 0;
    }

    & .survey-card {
      & > li:not(:first-of-type) {
        border-bottom: 1px solid var(--divider);
      }

      & .row {
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
        grid-template-areas: "title title title title na r-1 r-2 r-3 r-4 r-5";
        justify-items: center;
        align-items: center;

        padding: var(--sp-5);

        & > .face {
          color: var(--rank-color);
        }

        & > .dot {
          border-radius: 50%;
          width: var(--sp-5);
          height: var(--sp-5);

          background-color: var(--rank-color);

          &.option {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
            min-width: var(--sp-10);
            width: var(--sp-10);
            height: var(--sp-10);
            background-color: var(--background-card);

            &.selected {
              background-color: var(--rank-color);
            }
          }
        }

        .title {
          grid-area: title;
          justify-self: flex-start;
        }
        .r-1 {
          --rank-color: var(--secondary-dark);
          grid-area: r-1;
        }
        .r-2 {
          --rank-color: var(--secondary-light);
          grid-area: r-2;
        }
        .r-3 {
          --rank-color: var(--text-primary);
          grid-area: r-3;
        }
        .r-4 {
          --rank-color: var(--primary-light);
          grid-area: r-4;
        }
        .r-5 {
          --rank-color: var(--primary-dark);
          grid-area: r-5;
        }
      }
    }

    & .longform-card {
      background-color: var(--background-default);
    }
  `,
};

// TODO: use swr?
// TODO move to api
const getReview = async ({ queryKey: [, { id }] }) => {
  const response = await fetch(`/api/review/${id}`);

  const data = response.json();
  return data;
};

export const Review: React.FC = () => {
  const params = useParams();
  const { data, isLoading } = useQuery(
    ["api/review", { id: params.id }],
    getReview
  );

  const { isAuthenticated } = useUser();

  if (isLoading) return <PageLoadingSpinner />;

  // TODO remove this
  const cleanId = data.creatorId.startsWith("email|")
    ? data.creatorId.substring(6)
    : data.creatorId;
  return (
    <Styled.Container>
      <div className="bar">
        <Link variant="text" className="body1 back-button" to="/search">
          <ChevronLeft />
          {tdt("Back to Search")}
        </Link>
        {isAuthenticated ? (
          <Link
            variant="text"
            className="body1 back-button"
            to="/reviewer/review/create"
          >
            <AddIcon />
            {tdt("Add your own review")}
          </Link>
        ) : (
          <div />
        )}
      </div>
      <Card className="review-card">
        <div className="header">
          <h1 className="h2 title">{data.reviewTitle}</h1>
          <span className="date">{formatDate(data.created)}</span>
          <span className="rating">
            <span className="sr-only">{tdt(`${data.rating} out of 5`)}</span>
            <Stars rating={data.rating} />
          </span>
          <span className="author">
            {tdt(`Reviewed by `)}
            <Link to={`/profile/${cleanId}`}>
              {data.creatorName ?? "CAY User"}
            </Link>
          </span>
          <PhotoGallery imgs={data.fileIds} />
          <ul className="at-a-glance">
            <li className="bool">
              <span className="h5">{tdt("Would purchase again?")}</span>
              <span
                className="h5 response"
                data-response={data.surveyPurchaseAgain}
              >
                {data.surveyPurchaseAgain === "true" ? tdt("Yes") : tdt("No")}
              </span>
            </li>
            <li className="bool">
              <span className="h5">{tdt("Would recommend?")}</span>
              <span
                className="h5 response"
                data-response={data.surveyRecommend}
              >
                {data.surveyRecommend === "true" ? tdt("Yes") : tdt("No")}
              </span>
            </li>
            {!!data.pros.length && (
              <li className="h5 list">
                {tdt("Pros:")}
                <ul>
                  {data.pros
                    .filter(({ value }: any) => value)
                    .map(({ value }: any) => (
                      <li>{value}</li>
                    ))}
                </ul>
              </li>
            )}
            {!!data.cons.length && (
              <li className="h5 list">
                {tdt("Cons:")}
                <ul>
                  {data.cons
                    .filter(({ value }: any) => value)
                    .map(({ value }: any) => (
                      <li>{value}</li>
                    ))}
                </ul>
              </li>
            )}
            {!!data.link && (
              <li className="h5 link">
                {tdt("Reviewed Link:")}
                <ExternalLink link={data.link} />
              </li>
            )}
          </ul>
        </div>
        <p className="body1 review">{`"${data.review}"`}</p>
        <SurveyCard data={data} />
        <LongformCard data={data} />
      </Card>
      {isAuthenticated && (
        <Link
          variant="text"
          className="body1 back-button"
          to="/reviewer/review/create"
        >
          <AddIcon />
          {tdt("Add your own review")}
        </Link>
      )}
    </Styled.Container>
  );
};

const rateItems = [
  "surveyRateValue",
  "surveyRateParking",
  "surveyRateService",
  "surveyRateReliability",
  "surveyRateIntegrity",
  "surveyRateFeel",
];

const rateMap: Record<string, string> = {
  surveyRateValue: tdt(
    "Satisfaction with the service or product you received?."
  ),
  surveyRateParking: tdt(
    "Product/service access, i.e. parking, ordering, and shipping."
  ),
  surveyRateService: tdt("Customer service."),
  surveyRateReliability: tdt("Reliability."),
  surveyRateIntegrity: tdt("Quality of the goods or service."),
  surveyRateFeel: tdt("How did the purchase make you feel?"),
};

const SurveyCard: React.FC<{ data: any }> = ({ data }) => (
  <ul className="survey-card">
    <li className="row" aria-hidden>
      <Face1Icon className="face r-1" />
      <Face2Icon className="face r-2" />
      <Face3Icon className="face r-3" />
      <Face4Icon className="face r-4" />
      <Face5Icon className="face r-5" />
    </li>
    {rateItems
      .filter((item) => data[item] || data[item] === 0)
      .map((item) => (
        <li key={item} className="row">
          <span className="h5 title">{rateMap[item]}</span>
          <span className="sr-only">{tdt(`${data[item]} out of 5`)}</span>
          {Array(5)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className={cnc(
                  `r-${i + 1}`,
                  "dot",
                  "option",
                  `${i + 1}` === data[item] && "selected"
                )}
                aria-hidden
              />
            ))}
        </li>
      ))}
  </ul>
);

const longformItems = [
  "surveyAdditionalWants",
  "surveyNeverStop",
  "surveyHaventAsked",
];

const longformQuestions: Record<string, string> = {
  surveyAdditionalWants: tdt(
    "What additional products and services would you like to see?"
  ),
  surveyNeverStop: tdt("What should the company never stop doing?"),
  surveyHaventAsked: tdt("What haven’t we asked you yet?"),
};

const LongformCard: React.FC<{ data: any }> = ({ data }) => {
  const longformItemsComps = longformItems
    .filter((item) => data[item])
    .map((item) => (
      <Accordion.Item>
        <Accordion.Heading>{longformQuestions[item]}</Accordion.Heading>
        <Accordion.Panel>{data[item]}</Accordion.Panel>
      </Accordion.Item>
    ));
  return (
    <>
      {!!longformItemsComps.length && (
        <Card className="longform-card">
          <Accordion>{longformItemsComps}</Accordion>
        </Card>
      )}
    </>
  );
};

const ExternalLink: React.FC<{ link: string }> = ({ link }) => {
  const handleClick = (event: any) => {
    const proceed = confirm(
      tdt(
        "This link goes to an external website. You are leaving checkayou.com."
      )
    );
    if (!proceed) event.preventDefault();
  };
  return (
    <a
      className="link"
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      onClick={handleClick}
    >
      {link}
    </a>
  );
};

const cdnUrl = (id) =>
  `https://checkayou.com/cdn-cgi/imagedelivery/f35s28lfTO2XDQy5iJIxrA/${id}/thumbnail`;

const cdnUrlFull = (id) =>
  `https://checkayou.com/cdn-cgi/imagedelivery/f35s28lfTO2XDQy5iJIxrA/${id}/public`;

const PhotoGallery: React.FC<{ imgs: any[] }> = ({ imgs }) => {
  const [firstImgId, ...imgIds] = imgs ?? [];
  if (!firstImgId) return null;

  return (
    <div className="images">
      <div className="large-img">
        <ModalImage
          className="large-img"
          hideDownload
          hideZoom
          small={cdnUrl(firstImgId)}
          medium={cdnUrlFull(firstImgId)}
        />
      </div>
      <div className="img-list">
        {imgIds.map((id) => (
          <ModalImage
            className="small-img"
            key={id}
            hideDownload
            hideZoom
            small={cdnUrl(id)}
            medium={cdnUrlFull(id)}
          />
        ))}
      </div>
    </div>
  );
};
