import React from "react";
import { css } from "goober";
import { cnc } from "theme";

const Styled = {
  cardStyles: css`
    border-radius: var(--sp-6);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
    width: 100%;

    padding: var(--sp-4);

    background-color: var(--background-card);
    color: var(--text-primary);
  `,
};

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const Card: React.FC<Props> = ({
  as: Ele = "div",
  className,
  children,
}) => <Ele className={cnc(Styled.cardStyles, className)}>{children}</Ele>;
