import React, { useMemo, useState } from "react";
import { styled } from "goober";
import type { SearchResult } from "shared/SearchInput";
import { SearchBar, useSearchResults } from "shared/SearchInput";
import { Link } from "react-router-dom";
import { formatDate, tdt } from "i18n";
import { utils } from "theme";
import { Card } from "shared/Card";
import { Stars } from "shared/Stars";
import { LoadingSpinner } from "shared/LoadingSpinner";
import { useSearchParams } from "react-router-dom";
import { Button } from "shared/Button";

const Styled = {
  Container: styled("div")`
    margin: 0 auto;
    max-width: ${utils.containerWidth};
    width: 100%;

    padding: var(--sp-4);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-4);

    & .query-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--sp-2);
    }

    & ul {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);

      & li {
        display: flex;
        flex-direction: column;
        gap: var(--sp-2);

        & .title-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: var(--sp-2);
        }
      }
    }

    & .paging {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
  `,
};

const getIntOrDefault = (
  searchParams: URLSearchParams,
  key: string,
  val: number
) => {
  const param = Number.parseInt(searchParams.get(key));
  if (!Number.isInteger(param)) return val;
  return param;
};
export const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q") ?? "";
  const page = Math.max(getIntOrDefault(searchParams, "page", 1) - 1, 0);
  const pageSize = Math.min(getIntOrDefault(searchParams, "pageSize", 10), 30);
  const { data, isLoading } = useSearchResults({ query, page, pageSize });

  const onSubmit = (newQuery) => {
    setSearchParams(
      {
        q: newQuery,
        page: `${page + 1}`,
        pageSize: `${pageSize}`,
      },
      { replace: true }
    );
  };

  const setPage = (newPage) => {
    setSearchParams(
      {
        q: query,
        page: newPage,
        pageSize: `${pageSize}`,
      },
      { replace: true }
    );
  };

  const results = data?.hits ?? [];
  const totalPages = data?.nbPages;
  const totalHits = data?.nbHits;
  return (
    <Styled.Container>
      <div className="query-container">
        <SearchBar size="medium" onSubmit={onSubmit} initialValue={query} />
        {!!results.length &&
          !isLoading &&
          (totalPages > 1
            ? tdt(
                `Showing ${
                  results.length
                } of ${totalHits} total results. Page ${
                  page + 1
                } of ${totalPages}:`
              )
            : tdt(`Showing ${totalHits} results:`))}
      </div>
      {!isLoading ? (
        <>
          <ul>
            {results.map((result) => (
              <Result key={result.objectID} {...result} />
            ))}
          </ul>
          {totalPages > 1 && (
            <Pagination
              page={page + 1}
              totalPages={totalPages}
              setPage={setPage}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Styled.Container>
  );
};

export function Pagination({ page, totalPages, setPage }) {
  return (
    <div className="paging">
      <Button
        variant="text"
        size="large"
        disabled={page === 0}
        onClick={() => setPage(page - 1)}
      >
        {"<"}
      </Button>
      {tdt(`${page} / ${totalPages}`)}
      <Button
        variant="text"
        size="large"
        disabled={page === totalPages}
        onClick={() => setPage(page + 1)}
      >
        {">"}
      </Button>
    </div>
  );
}

const Result: React.FC<SearchResult> = ({
  reviewTitle,
  objectID,
  created,
  rating,
  creatorName,
  review,
}) => {
  const cutTitle = useMemo(
    () => reviewTitle.split(" ").slice(0, 15).join(" "),
    []
  );
  const cutReview = useMemo(() => review.split(" ").slice(0, 40).join(" "), []);
  return (
    <Link to={`/review/${objectID}`}>
      <Card as="li">
        <div className="title-container">
          <span className="h4 primary-dark">
            {cutTitle.length !== reviewTitle.length
              ? tdt(`${cutTitle} ...`)
              : reviewTitle}
          </span>
          <span className="h5 date">{formatDate(created)}</span>
        </div>
        <span className="rating">
          <span className="sr-only">{tdt(`${rating} out of 5`)}</span>
          <Stars rating={rating} />
        </span>
        <span className="h5 author">
          {tdt(`Reviewed by `)}
          {tdt(creatorName ?? "CAY User")}
        </span>
        <span className="body1 blurb">{tdt(`${cutReview} ...`)}</span>
      </Card>
    </Link>
  );
};
