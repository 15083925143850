import React from "react";
import { css } from "goober";
import { cnc } from "theme";

export interface ButtonProps {
  variant?: "filled" | "outline" | "text";
  size?: "small" | "medium" | "large";
  color?: "primary";
  className?: string;
  as?: string;
  children: React.ReactNode;
}

export const Button: React.FC<
  ButtonProps & React.ComponentPropsWithRef<"button">
> = ({ variant = "filled", size, color, className, children, ...props }) => {
  return (
    <button
      type="button"
      className={cnc(buttonStyles, className)}
      data-variant={variant}
      data-size={size}
      data-color={color}
      {...props}
    >
      {children}
    </button>
  );
};

export const buttonStyles = css`
  --width: fit-content;

  width: var(--width);
  text-align: center;
  white-space: nowrap;
  border-radius: var(--sp-6);

  /* Colors */
  &:where([data-color="primary"]) {
    --bg: var(--primary-main);
    --color: var(--primary-contrast-text);
  }

  /* Sizes */
  &:where([data-size="large"]) {
    border: var(--sp-1_5) solid;
    padding: var(--sp-7) var(--sp-17);

    font-size: var(--sp-6);
    line-height: var(--sp-6);
  }
  &:where([data-size="medium"]) {
    border: var(--sp-0_5) solid;
    padding: var(--sp-3);

    font-size: var(--sp-6);
    line-height: var(--sp-6);
    font-weight: 400;
  }
  &:where([data-size="small"]) {
    border: 1px solid;
    padding: var(--sp-3);

    font-size: var(--sp-4);
    line-height: var(--sp-4);
    font-weight: 700;
  }

  /* Variants */
  &:where([data-variant="filled"]) {
    border: none;
    background-color: var(--bg);
    color: var(--color);
  }
  &:where([data-variant="outline"]) {
    border-color: var(--bg);
    color: var(--bg);
  }
  &:where([data-variant="text"]) {
    border: none;
    color: var(--bg);
  }
`;
