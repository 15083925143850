import React from "react";
import { styled } from "goober";
import { Link } from "shared/Link";
import { tdt } from "i18n";

const Styled = {
  Footer: styled("footer")`
    width: 100%;
    height: 300px;

    padding: var(--sp-8);
    background-color: var(--background-footer);
    color: var(--text-contrast);
  `,
  Nav: styled("nav")`
    height: 100%;

    display: grid;
    grid-template-columns: min-content min-content 1fr;
    grid-column-gap: var(--sp-6);

    & > ul {
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);
      justify-content: flex-end;
    }
  `,
  FooterLogo: styled("h4")`
    font-size: var(--sp-12);
    font-weight: 400;
  `,
};

export const Footer: React.FC = () => (
  <Styled.Footer>
    <Styled.Nav>
      <Styled.FooterLogo>{tdt("checkayou")}</Styled.FooterLogo>
      <ul>
        <li>
          <Link to="/about" variant="text" className="h5">
            {tdt("About Us")}
          </Link>
        </li>
        <li>
          <Link to="/terms-and-conditions" variant="text" className="h5">
            {tdt("Terms & Conditions")}
          </Link>
        </li>
        <li>
          <Link to="/privacy" variant="text" className="h5">
            {tdt("Privacy")}
          </Link>
        </li>
        <li>
          <Link to="/contact-us" variant="text" className="h5">
            {tdt("Contact Us")}
          </Link>
        </li>
      </ul>
    </Styled.Nav>
  </Styled.Footer>
);
