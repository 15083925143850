import React from "react";
import { styled } from "goober";
import { tdt } from "i18n";
import Swoosh from "theme/inline-assets/swoosh.svg";
import { SearchBar } from "shared/SearchInput";
// import { SearchProvider, SearchBox } from "@elastic/react-search-ui";
import { useNavigate } from "react-router";
import { utils } from "theme";

const Styled = {
  Container: styled("div")`
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    height: 100%;

    position: relative;
    padding: 0 var(--sp-6);

    color: var(--secondary-text);

    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    ${utils.lg} {
      top: 50%;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--sp-7);

    & form {
      width: 100%;
    }
  `,
  Background: styled("div")`
    position: absolute;
    inset: 0;

    width: 100%;

    transform: scaleY(-1) translateY(-10%);
    color: var(--primary-main);
  `,
};

export const Landing: React.FC = () => {
  const navigate = useNavigate();
  const onSearch = (query) => {
    navigate(`/search?q=${query}`);
  };
  return (
    <>
      <Styled.Background>
        <Swoosh aria-hidden />
      </Styled.Background>
      <Styled.Container>
        <h1 className="primary-dark">{tdt("checkayou")}</h1>
        <SearchBar size="large" onSubmit={onSearch} />
      </Styled.Container>
    </>
  );
};
