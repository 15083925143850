import React from "react";
import { styled } from "goober";
import { tdt } from "i18n";
import { useAuth } from "shared/hooks/use-auth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReviewForm } from "pages/CreateReview/ReviewForm";
import { Button } from "shared/Button";
import { useNavigate, useParams } from "react-router";
import { LoadingSpinner, PageLoadingSpinner } from "shared/LoadingSpinner";

const Styled = {
  Container: styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .title {
      width: 100%;

      padding: var(--sp-9);
      background-color: var(--primary-main);
      color: var(--primary-contrast-text);

      text-align: center;
    }

    & > .content {
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;

      padding: var(--sp-10);
    }
  `,
  SubmitButtons: styled("div")`
    grid-column: 1 / -1;

    padding: var(--sp-5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--sp-6);
  `,
};

// TODO: use swr?
// TODO move to api
const putReview = (id: string, token: string) => async (review: any) => {
  const response = await fetch(`/api/reviewer/review/${id}`, {
    method: "PUT",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(review),
  });

  const data = response.json();
  return data;
};

const getReview =
  (token: string) =>
  async ({ queryKey: [, { id }] }) => {
    const response = await fetch(`/api/reviewer/review/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = response.json();
    return data;
  };

export const EditReview: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const token = useAuth();
  const { data, isLoading } = useQuery(
    ["api/reviewer/review", { id: params.id, token }],
    getReview(token!),
    {
      enabled: !!token,
    }
  );
  const { mutate, isLoading: isSubmitting } = useMutation(
    putReview(params.id!, token!),
    {
      onSuccess() {
        navigate(`/reviewer`);
      },
    }
  );

  if (!token || isLoading) return <PageLoadingSpinner />;

  return (
    <Styled.Container>
      <h2 className="title">{tdt("Update a Review")}</h2>
      <div className="content">
        <ReviewForm onSubmit={mutate} initialData={data}>
          <SubmitOptions
            isPublished={data.published}
            isSubmitting={isSubmitting}
          />
        </ReviewForm>
      </div>
    </Styled.Container>
  );
};

interface SubmitProps {
  onSubmit?: (action: "publish" | "update") => void;
  isFormLoading?: boolean;
  isSubmitting: boolean;
  isPublished: boolean;
}

const SubmitOptions: React.FC<SubmitProps> = ({
  onSubmit,
  isPublished,
  isSubmitting,
  isFormLoading,
}) => {
  const handleSubmit =
    (action: "publish" | "update") =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onSubmit!(action);
    };

  const isLoading = isSubmitting || isFormLoading;
  return (
    <Styled.SubmitButtons>
      {!isPublished ? (
        <>
          <Button
            onClick={handleSubmit("update")}
            variant="outline"
            size="medium"
            color="primary"
            disabled={isLoading}
          >
            {!isLoading ? tdt("Update Draft") : <LoadingSpinner />}
          </Button>
          <Button
            onClick={handleSubmit("publish")}
            variant="filled"
            size="medium"
            color="primary"
            disabled={isLoading}
          >
            {!isLoading ? tdt("Update and Publish") : <LoadingSpinner />}
          </Button>
        </>
      ) : (
        <Button
          onClick={handleSubmit("update")}
          variant="filled"
          size="medium"
          color="primary"
          disabled={isLoading}
        >
          {!isLoading ? tdt("Update") : <LoadingSpinner />}
        </Button>
      )}
    </Styled.SubmitButtons>
  );
};
