import React from "react";
import { styled } from "goober";
import { Outlet } from "react-router-dom";
import { utils } from "theme";
import { SkipToNav } from "./SkipToNav";
import { Header } from "./Header";
import { Footer } from "./Footer";

const Styled = {
  ContentWrapper: styled("main")`
    /* HACK: make sure the swoosh is below the footer */
    z-index: -1;
    width: 100%;
    min-height: calc(100vh - ${utils.headerHeight});

    position: relative;
    display: flex;
    flex-direction: column;

    background-color: var(--background-default);
  `,
};

export const DesktopWrapper: React.FC = () => (
  <>
    <SkipToNav />
    <Header />
    <Styled.ContentWrapper id="main">
      <Outlet />
    </Styled.ContentWrapper>
    <Footer />
  </>
);
