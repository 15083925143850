"use strict";

// Look into:
/* 
 vanilla-extract.style
 https://vanilla-extract.style/documentation/packages/sprinkles/
 remix
 lexical
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { setup } from "goober";
import { prefix } from "goober/prefixer";

import { App } from "./App";

setup(React.createElement, prefix, undefined, (props: any) => {
  for (const prop in props) {
    if (!prop.startsWith("$")) continue;
    delete props[prop];
  }
});

const container = document.getElementById("check-a-root");

const root = createRoot(container!);
root.render(React.createElement(App, {}));
