import React from "react";
import { styled } from "goober";
import { tdt } from "i18n";
import { useAuth } from "shared/hooks/use-auth";
import { useMutation } from "@tanstack/react-query";
import { ReviewForm } from "pages/CreateReview/ReviewForm";
import { Button } from "shared/Button";
import { LoadingSpinner } from "shared/LoadingSpinner";
import { useNavigate } from "react-router";

const Styled = {
  Container: styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > .title {
      width: 100%;

      padding: var(--sp-9);
      background-color: var(--primary-main);
      color: var(--primary-contrast-text);

      text-align: center;
    }

    & > .content {
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;

      padding: var(--sp-10);
    }
  `,
  SubmitButtons: styled("div")`
    grid-column: 1 / -1;

    padding: var(--sp-5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: var(--sp-6);
  `,
};

// TODO: use swr?
// TODO move to api
const postReview = (token: string) => async (review: any) => {
  const response = await fetch("/api/reviewer/review", {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(review),
  });
  console.log(response);

  const data = response.json();
  return data;
};

export const CreateReview: React.FC = () => {
  const navigate = useNavigate();
  const token = useAuth();
  const { mutate, isLoading } = useMutation(postReview(token!), {
    onSuccess(data) {
      navigate(`../${data.id}`);
    },
  });
  return (
    <Styled.Container>
      <h2 className="title">{tdt("Start a Review")}</h2>
      <div className="content">
        <ReviewForm onSubmit={mutate}>
          <SubmitOptions isSubmitting={isLoading} />
        </ReviewForm>
      </div>
    </Styled.Container>
  );
};

interface SubmitProps {
  onSubmit?: (action: "publish" | "save") => void;
  isFormLoading?: boolean;
  isSubmitting: boolean;
}

const SubmitOptions: React.FC<SubmitProps> = ({
  onSubmit,
  isSubmitting,
  isFormLoading,
}) => {
  const handleSubmit =
    (action: "publish" | "save") =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onSubmit!(action);
    };

  const isLoading = isSubmitting || isFormLoading;
  return (
    <Styled.SubmitButtons>
      <Button
        onClick={handleSubmit("save")}
        variant="outline"
        size="medium"
        color="primary"
        disabled={isLoading}
      >
        {!isLoading ? tdt("Save Draft") : <LoadingSpinner />}
      </Button>
      <Button
        onClick={handleSubmit("publish")}
        variant="filled"
        size="medium"
        color="primary"
        disabled={isLoading}
      >
        {!isLoading ? tdt("Save and Publish") : <LoadingSpinner />}
      </Button>
    </Styled.SubmitButtons>
  );
};
