import React from "react";
import { styled } from "goober";

const Styled = {
  Radio: styled("input", React.forwardRef)`
    appearance: none;
    margin: 0;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);

    min-width: var(--sp-10);
    width: var(--sp-10);
    height: var(--sp-10);

    display: grid;
    place-content: center;

    font: inherit;
    background-color: var(--background-card);
    color: currentColor;

    &::before {
      content: "";
      border-radius: 50%;
      width: var(--sp-5);
      height: var(--sp-5);

      background-color: var(--text-primary);

      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }

    &:checked::before {
      transform: scale(1);
    }
  `,
};

export const Radio = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithRef<"input">
>(function Radio(props, ref) {
  return <Styled.Radio ref={ref} type="radio" {...props} />;
});
