import React from "react";
import { styled } from "goober";

const Styled = {
  LoadingSpinner: styled("div")`
    width: var(--sp-19);
    height: var(--sp-19);
    position: relative;

    & > div {
      border-radius: 5px;
      position: absolute;
      left: var(--sp-2);
      width: var(--sp-4);
      background: var(--primary-light);
      animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    & > div:nth-child(1) {
      left: var(--sp-2);
      animation-delay: -0.24s;
    }

    & > div:nth-child(2) {
      left: var(--sp-8);
      animation-delay: -0.12s;
    }

    & > div:nth-child(3) {
      left: var(--sp-14);
      animation-delay: 0;
    }

    @keyframes lds-facebook {
      0% {
        top: 8px;
        height: 64px;
      }
      50%,
      100% {
        top: 24px;
        height: 32px;
      }
    }
  `,
  Container: styled("div")`
    width: 100%;

    padding: var(--sp-9);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
};

export const LoadingSpinner: React.FC = () => (
  <Styled.LoadingSpinner>
    <div />
    <div />
    <div />
  </Styled.LoadingSpinner>
);

export const PageLoadingSpinner: React.FC = () => (
  <Styled.Container>
    <LoadingSpinner />
  </Styled.Container>
);
