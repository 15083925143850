import React from "react";
import { styled } from "goober";
import * as AvatarBase from "@radix-ui/react-avatar";

const Styled = {
  Avatar: styled(AvatarBase.Root)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
    user-select: none;
    width: 133px;
    height: 133px;
    border-radius: 50%;
    background-color: var(--background-default);
  `,
  Image: styled(AvatarBase.Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  `,
  Fallback: styled(AvatarBase.Fallback)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-default);
    color: var(--primary-main);
    font-size: 55px;
    font-weight: 500;
  `,
};

type AvatarType = React.FC<{ children: React.ReactNode }> & {
  Image: React.FC<AvatarBase.AvatarImageProps>;
  Fallback: React.FC<AvatarBase.AvatarFallbackProps>;
};
export const Avatar: AvatarType = ({ children }) => (
  <Styled.Avatar>{children}</Styled.Avatar>
);
Avatar.Image = Styled.Image;
Avatar.Fallback = Styled.Fallback;
