import React, {
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore,
} from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import {
  type User,
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";

// TODO: move to env vars
const DOMAIN = "auth.checkayou.com";
const CLIENT_ID = "npfEcMytbQlUZ9L3gHhGUjzeIhWsOcrW";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || "/reviewer/dashboard");
  };

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={`${window?.location.origin}/reviewer/dashboard`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export const AuthedWrapper: React.FC = () => {
  const Component = withAuthenticationRequired(() => <Outlet />);
  return <Component />;
};

export function useAuthActions() {
  const { loginWithRedirect, logout } = useAuth0();

  return {
    logout: () => logout({ returnTo: window.location.origin }),
    login: loginWithRedirect,
  };
}

const namespace = "https://checkayou.com/";
interface UserShape extends User {
  [`${namespace}verified`]?: boolean;
}
export function useUser() {
  const { isLoading, isAuthenticated, error, user } = useAuth0<UserShape>();

  if (user) {
    const attrs = Object.entries(user)?.reduce((acc, [key, attr]) => {
      console;
      if (key.startsWith(namespace)) {
        acc[key.substring(namespace.length)] = attr;
      }
      return acc;
    }, {});

    user.attrs = attrs;
  }

  return { isLoading, isAuthenticated, error, user };
}

// TODO suspencsify this
export function useAuth() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently({
        audience: "checkayou.com/api",
        scope: "create:review",
      });
      setToken(token);
    }
    getToken();
  }, []);

  return token;
}

export function useAuthPromise() {
  const { getAccessTokenSilently } = useAuth0();
  const [token] = useState<Promise<string>>(
    getAccessTokenSilently({
      audience: "checkayou.com/api",
      scope: "create:review",
    })
  );

  return token;
}
