import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { buttonStyles, ButtonProps } from "shared/Button";
import { cnc } from "theme";

type RouterLinkProps = LinkProps &
  React.RefAttributes<HTMLAnchorElement> &
  ButtonProps;
interface Props extends RouterLinkProps {
  className?: string;
  children: React.ReactNode;
}

export const Link: React.FC<Props> = ({
  variant = "text",
  size,
  color,
  className,
  children,
  ...props
}) => (
  <RouterLink
    data-variant={variant}
    data-size={size}
    data-color={color}
    className={cnc(buttonStyles, className)}
    {...props}
  >
    {children}
  </RouterLink>
);
