import React from "react";
import { styled } from "goober";
import { formatDate, tdt } from "i18n";
import { utils } from "theme";
import { Link } from "shared/Link";
import { Card } from "shared/Card";
import { useAuth, useUser } from "shared/hooks/use-auth";
import { useQuery } from "@tanstack/react-query";
import { PageLoadingSpinner } from "shared/LoadingSpinner";
import { Stars } from "shared/Stars";
import { Navigate } from "react-router";

const Styled = {
  Container: styled("div")`
    width: 100%;

    & > .content {
      margin: 0 auto;
      max-width: ${utils.containerWidth};
      width: 100%;

      padding: var(--sp-22) var(--sp-6);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-14);
    }

    & > .greeting {
      width: 100%;
      padding: var(--sp-9);

      background-color: var(--primary-main);
      color: var(--primary-contrast-text);
      text-align: center;
    }
  `,
  ReviewTable: styled("div")`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-5);

    table {
      width: 100%;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid var(--divider);
    }

    .item {
      width: 100%;

      padding: var(--sp-4) 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-row-gap: var(--sp-4);
      grid-template-areas:
        "date date link"
        "star star star"
        "title title na";
    }
    .item.draft {
      grid-template-areas:
        "date date link"
        "title title na";
    }
    .date {
      grid-area: date;
      text-align: end;
    }
    .link {
      grid-area: link;
      text-align: end;
    }
    .title {
      color: var(--primary-dark);
      grid-area: title;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .star {
      grid-area: star;
      display: flex;
      flex-direction: row;

      color: var(--secondary-dark);
    }
  `,
};

// TODO move to api
const getMe = (token: string) => async () => {
  const response = await fetch("/api/reviewer/me", {
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response);

  const data = response.json();
  return data;
};

export const Dashboard: React.FC = () => {
  const { user } = useUser();
  const token = useAuth();
  const { isLoading, data } = useQuery(["getMe", token], getMe(token!), {
    enabled: !!token,
  });

  if (!token || isLoading || !user) return <PageLoadingSpinner />;

  console.log({ user });
  if (user.attrs.verified !== "complete")
    return <Navigate replace to="../verify" />;

  const { reviews } = data;
  const createdReviews = reviews.filter(({ published }) => published);
  const draftReviews = reviews.filter(({ published }) => !published);
  return (
    <Styled.Container>
      <h1 className="sr-only">{tdt("Your dashboard")}</h1>
      <p className="h2 h2 greeting">{tdt(`Welcome!`)}</p>
      <div className="content">
        <Link
          to="../review/create"
          variant="filled"
          size="large"
          color="primary"
        >
          {tdt("Start a Review")}
        </Link>
        {!createdReviews.length && !draftReviews.length ? (
          <p className="body1">
            {tdt("It looks like you haven't made any reviews yet.")}
          </p>
        ) : (
          <>
            {!!draftReviews.length && (
              <DraftSection>
                {draftReviews.map((draft) => (
                  <DraftItem key={draft.id} {...draft} />
                ))}
              </DraftSection>
            )}
            {!!createdReviews.length && (
              <ReviewSection>
                {createdReviews.map((review) => (
                  <ReviewItem key={review.id} {...review} />
                ))}
              </ReviewSection>
            )}
          </>
        )}
      </div>
    </Styled.Container>
  );
};

const DraftSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Styled.ReviewTable>
    <h3 id="drafts-label">{tdt("Drafts")}</h3>
    <Card className="card">
      <table aria-describedby="drafts-label">
        <thead className="sr-only">
          <tr>
            <th>{tdt("date")}</th>
            <th>{tdt("title")}</th>
            <th>{tdt("edit")}</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Card>
  </Styled.ReviewTable>
);

interface DraftProps {
  id: string;
  created: string;
  reviewTitle: string;
}

const DraftItem: React.FC<DraftProps> = ({ id, created, reviewTitle }) => (
  <tr className="item draft">
    <td className="h5 date">{formatDate(created)}</td>
    <td className="h4 title">{reviewTitle}</td>
    <td className="link">
      <Link to={`../review/${id}`} variant="text" size="small">
        {tdt("Edit")}
      </Link>
    </td>
  </tr>
);

export const ReviewSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Styled.ReviewTable>
    {/* <h3 id="reviews-label">{tdt("Past Reviews")}</h3> */}
    <Card className="card">
      <table aria-describedby="reviews-label">
        <thead className="sr-only">
          <tr>
            <th>{tdt("date")}</th>
            <th>{tdt("title")}</th>
            <th>{tdt("edit")}</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Card>
  </Styled.ReviewTable>
);

interface ReviewProps {
  id: string;
  created: string;
  rating: string;
  reviewTitle: string;
}

export const ReviewItem: React.FC<ReviewProps> = ({
  id,
  created,
  rating,
  reviewTitle,
}) => (
  <tr className="item">
    <td className="h5 date">{formatDate(created)}</td>
    <td className="star">
      <span className="sr-only">{tdt(`${rating} out of 5`)}</span>
      <Stars rating={rating} />
    </td>
    <td className="h4 title">{reviewTitle}</td>
    <td className="link">
      <Link to={`../review/${id}`} variant="text" size="small">
        {tdt("View")}
      </Link>
    </td>
  </tr>
);
