import React from "react";
import { css, styled } from "goober";
import { Link } from "shared/Link";
import { Button } from "shared/Button";
import { tdt } from "i18n";
import { cnc, utils } from "theme";
import { useAuthActions, useUser } from "shared/hooks/use-auth";

const Styled = {
  linkStyles: css`
    width: min-content;
    color: var(--primary-contrast-text);
    font-weight: 400;
  `,
  Header: styled("header")`
    width: 100%;
    height: ${utils.headerHeight};

    padding: 0 var(--sp-8);
    display: grid;
    grid-template-columns: 1fr minmax(0, min-content);
    align-items: center;

    background-color: var(--primary-dark);
  `,
  NavList: styled("nav")`
    & > ul {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: var(--sp-3);
    }
  `,
};

export const Header: React.FC = () => {
  const { isAuthenticated } = useUser();

  return (
    <Styled.Header>
      <Link
        to="/"
        aria-label={tdt("checkayou homepage")}
        className={cnc("h2", Styled.linkStyles)}
      >
        {tdt("checkayou")}
      </Link>
      <Styled.NavList>
        <ul>{isAuthenticated ? <AuthedHeaderActions /> : <HeaderActions />}</ul>
      </Styled.NavList>
    </Styled.Header>
  );
};

const HeaderActions: React.FC = () => {
  const { login } = useAuthActions();

  return (
    <li>
      <Button
        onClick={login}
        role="link"
        variant="text"
        color="primary"
        size="small"
        className="primary-contrast-text"
      >
        {tdt("Sign In/Sign Up")}
      </Button>
    </li>
  );
};

const AuthedHeaderActions: React.FC = () => {
  const { logout } = useAuthActions();

  return (
    <>
      <li>
        <Link
          to="/reviewer/profile"
          variant="text"
          color="primary"
          size="small"
          className="primary-contrast-text"
        >
          {tdt("Profile")}
        </Link>
      </li>
      <li>
        <Link
          to="/reviewer"
          variant="text"
          color="primary"
          size="small"
          className="primary-contrast-text"
        >
          {tdt("Dashboard")}
        </Link>
      </li>
      <li>
        <Button
          onClick={logout}
          role="link"
          variant="text"
          color="primary"
          size="small"
          className="primary-contrast-text"
        >
          {tdt("Logout")}
        </Button>
      </li>
    </>
  );
};
