import { styled } from "goober";
import { utils } from "theme";

export const MarkdownThemeProvider = styled("div")`
  margin: 0 auto;
  max-width: ${utils.containerWidth};
  width: 100%;

  padding: var(--sp-10) var(--sp-10) var(--sp-13);

  display: block;

  & > h1 {
    margin-bottom: var(--sp-4);
    border-bottom: 1px solid var(--divider);
  }

  & > h2,
  & > h3,
  & > h4 {
    margin-top: var(--sp-6);
    margin-bottom: var(--sp-4);
  }

  & > ul,
  & > ol {
    list-style: disc;
    margin-bottom: var(--sp-4);
    padding-left: var(--sp-8);

    & > li {
      position: relative;
      &:not(:first-of-type) {
        margin-top: var(--sp-1);
      }
    }
  }

  & > ol {
    list-style: decimal;
  }

  & > p {
    margin-bottom: var(--sp-4);
  }

  & > p a,
  & > ul a,
  & > ol a {
    color: var(--primary-main);
  }
`;
