import React from "react";
import { styled } from "goober";
import { utils } from "theme";
import { useUser } from "shared/hooks/use-auth";
import { Avatar } from "shared/Avatar";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { tdt } from "i18n";
import { Card } from "shared/Card";
import { LoadingSpinner } from "shared/LoadingSpinner";
import { ReviewItem, ReviewSection } from "pages/Dashbaord";

const cdnUrl = (id) =>
  `https://checkayou.com/cdn-cgi/imagedelivery/f35s28lfTO2XDQy5iJIxrA/${id}/avatar`;
const Styled = {
  Container: styled("div")`
    margin: 0 auto;
    max-width: ${utils.containerWidth};
    width: 100%;

    padding: var(--sp-4) var(--sp-4) var(--sp-20);
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-column-gap: var(--sp-4);

    & .info {
      position: sticky;
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);
      align-items: center;
    }

    & .reviews {
      display: flex;
      flex-direction: column;
      gap: var(--sp-9);

      align-items: center;

      & .no-result {
        text-align: center;
      }
    }
  `,
};

// TODO: use swr?
// TODO move to api
const getReviewer = async ({ queryKey: [, { id }] }) => {
  const response = await fetch(`/api/profile/${id}`, {
    headers: { "content-type": "application/json" },
  });

  const data = response.json();
  return data;
};

export const Profile: React.FC = () => {
  const params = useParams();
  const { isAuthenticated, user } = useUser();
  const { data, isLoading } = useQuery(
    [`/api/profile/${params.id}`, { id: params.id }],
    getReviewer
  );

  if (isLoading)
    return (
      <Styled.Container>
        <LoadingSpinner />
      </Styled.Container>
    );

  return (
    <Styled.Container>
      <Card className="info">
        <Avatar>
          <Avatar.Image src={cdnUrl(data.avatarId)} />
          <Avatar.Fallback>{"✓"}</Avatar.Fallback>
        </Avatar>
        <h2 className="primary-dark">{data.displayName}</h2>
        <hr />
        <h2 className="primary-dark">{tdt("✓ Verified")}</h2>
        <hr />
        <h4>{tdt(`${data.reviews.length} Reviews`)}</h4>
        <p className="body">{data.bio}</p>
      </Card>
      <div className="reviews">
        {data.reviews.length ? (
          <ReviewSection>
            {data.reviews.map((review) => (
              <ReviewItem key={review.id} {...review} />
            ))}
          </ReviewSection>
        ) : (
          <Card className="no-result">
            <h3>{tdt("No reviews yet")}</h3>
          </Card>
        )}
      </div>
    </Styled.Container>
  );
};
