import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { css, styled } from "goober";
import { tdt } from "i18n";
import {
  type UseFormRegister,
  type Control,
  useForm,
  useFieldArray,
} from "react-hook-form";
import ImageUploadingModule, { type ErrorsType } from "react-images-uploading";
import { type ReviewShape, resolver } from "./review-validation";
import Face1Icon from "theme/inline-assets/face-1-icon.svg";
import Face2Icon from "theme/inline-assets/face-2-icon.svg";
import Face3Icon from "theme/inline-assets/face-3-icon.svg";
import Face4Icon from "theme/inline-assets/face-4-icon.svg";
import Face5Icon from "theme/inline-assets/face-5-icon.svg";
import AddIcon from "theme/inline-assets/add-icon.svg";
import CloseIcon from "theme/inline-assets/close-icon.svg";

import { Input, TextArea } from "shared/Input";
import { Accordion } from "shared/Accordion";
import { Card } from "shared/Card";
import { Radio } from "shared/Radio";
import { Button } from "shared/Button";
import { useAuthPromise } from "shared/hooks/use-auth";

const ImageUploading =
  ImageUploadingModule.default as typeof ImageUploadingModule;

const imgTypes = ["jpg", "jpeg", "gif", "png", "svg", "webp"];
const imgMaxSize = 10_000_000;
const maxUploads = 4;
const cdnUrl = (id) =>
  `https://checkayou.com/cdn-cgi/imagedelivery/f35s28lfTO2XDQy5iJIxrA/${id}/thumbnail`;

const Styled = {
  ReviewForm: styled("form")`
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: baseline;
    gap: var(--sp-4_5);

    .group {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4_5);
    }

    .full-width {
      grid-column: 1 / -1;
    }

    .bool-question {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: var(--sp-6);

      .radio-group {
        display: flex;
        flex-direction: row;
        gap: var(--sp-15);
        margin-left: auto;

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: var(--sp-6);
        }
      }
    }

    fieldset {
      display: contents;
    }

    & > hr {
      grid-column: 1 / -1;
    }
  `,
  rateBlockClass: css`
    grid-column: 1 / -1;

    & > .row {
      display: grid;
      grid-template-columns: repeat(10, minmax(0, 1fr));
      grid-template-areas: "title title title title na r-1 r-2 r-3 r-4 r-5";
      justify-items: center;
      align-items: center;

      padding: var(--sp-5);

      &:not(:last-child):not(:first-child) {
        border-bottom: 1px solid var(--divider);
      }

      & > .face {
        &[data-ranking="1"] {
          color: var(--secondary-dark);
        }
        &[data-ranking="3"] {
          color: var(--text-primary);
        }
        &[data-ranking="5"] {
          color: var(--primary-dark);
        }
        &[data-ranking="2"] {
          color: var(--secondary-main);
        }
        &[data-ranking="4"] {
          color: var(--primary-main);
        }
      }

      .title {
        grid-area: title;
        justify-self: flex-start;
      }
      .r-1 {
        grid-area: r-1;
      }
      .r-2 {
        grid-area: r-2;
      }
      .r-3 {
        grid-area: r-3;
      }
      .r-4 {
        grid-area: r-4;
      }
      .r-5 {
        grid-area: r-5;
      }
    }
  `,
  ImageUpload: styled("div")`
    --target-dim: var(--sp-22);
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    gap: var(--sp-4);

    & .upload-error {
      color: var(--secondary-main);
    }

    & > .container {
      display: flex;
      flex-direction: row;
      gap: var(--sp-4);

      & > .drop-target {
        border: 2px dashed var(--divider);
        width: var(--target-dim);
        height: var(--target-dim);
        padding: 0;

        & > button {
          width: 100%;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      & > .image {
        width: var(--target-dim);
        height: var(--target-dim);
        overflow: hidden;
        padding: 0;

        position: relative;

        & img {
          border-radius: var(--sp-6);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        & .remove {
          position: absolute;
          right: var(--sp-1);
          top: var(--sp-1);

          border-radius: 50%;
          background-color: var(--background-card);
          padding: var(--sp-2);

          & > svg {
            width: var(--sp-2);
            height: var(--sp-2);
          }
        }
      }
    }
  `,
};

const rateItems: (keyof ReviewShape)[] = [
  "rating",
  "surveyRateValue",
  "surveyRateParking",
  "surveyRateReliability",
  "surveyRateIntegrity",
  "surveyRateFeel",
];

const longformItems: (keyof ReviewShape)[] = [
  "review",
  "surveyAdditionalWants",
  "surveyCustomerAccess",
  "surveyNeverStop",
  "surveyHaventAsked",
];

interface FormItemProps {
  register: UseFormRegister<ReviewShape>;
  control?: Control<ReviewShape, any>;
}

interface ReviewFormProps {
  onSubmit(review: any): any;
  initialData?: ReviewShape;
  children: React.ReactNode;
}

async function uploadImage(token: string, file: File) {
  const response = await fetch("/api/reviewer/review/image", {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { id, uploadURL } = await response.json();

  const body = new FormData();
  body.append("file", file);

  await fetch(uploadURL, {
    body,
    method: "POST",
  });

  return id;
}

async function bulkUpload(token: Promise<string>, fileList: { file: File }[]) {
  token = await token;
  return await Promise.all(
    fileList.map(({ file }) => uploadImage(token, file))
  );
}

export const ReviewForm: React.FC<ReviewFormProps> = ({
  onSubmit,
  initialData,
  children,
}) => {
  // TODO validation
  // const { register, control, handleSubmit } = useForm<ReviewShape>({
  //   resolver,
  // });
  const token = useAuthPromise();
  const { register, control, handleSubmit, watch } = useForm<ReviewShape>({
    defaultValues: initialData,
  });
  const [images, setImages] = useState([]);
  const [isFormLoading, setFormLoading] = useState(false);

  const createSubmit = async (action: "publish" | "save") => {
    setFormLoading(true);
    const fileIds = await bulkUpload(token, images);
    handleSubmit((data) => {
      const formData = {
        ...data,
        pros: data.pros.filter(({ value }) => value),
        cons: data.cons.filter(({ value }) => value),
        fileIds: [...(initialData?.fileIds ?? []), ...fileIds],
      };
      console.log({ data: formData, action });
      onSubmit({ data: formData, action });
    })();
    setFormLoading(false);
  };

  return (
    <Styled.ReviewForm
      onSubmit={handleSubmit((data) => {
        console.log({ data });
      })}
    >
      <ProductInfo register={register} />
      <hr />

      <ProsCons pros register={register} watch={watch} control={control} />
      <hr />
      <ProsCons register={register} watch={watch} control={control} />
      <hr />

      <BoolQuestion register={register} propertyName={"surveyPurchaseAgain"} />
      <BoolQuestion register={register} propertyName={"surveyRecommend"} />
      <hr />

      <ImageUpload
        images={images}
        onChange={setImages}
        initialData={initialData?.fileIds}
      />
      <hr />

      <RateBlock>
        {rateItems.map((item) => (
          <RateItem key={item} register={register} propertyName={item} />
        ))}
      </RateBlock>
      <hr />

      <LongformBlock>
        {longformItems.map((item) => (
          <LongformItem key={item} register={register} propertyName={item} />
        ))}
      </LongformBlock>

      {React.cloneElement(React.Children.only(children), {
        onSubmit: createSubmit,
        isFormLoading,
      })}
    </Styled.ReviewForm>
  );
};

interface ImageUploadProps {
  onChange: (imgs: any[]) => void;
  images: any[];
  initialData: string[];
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  initialData = [],
  images,
  onChange,
}) => {
  return (
    <Styled.ImageUpload>
      <label htmlFor="imgs" className="h5">
        {tdt("Images/ Receipts")}
      </label>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxUploads}
        acceptType={imgTypes}
        maxFileSize={imgMaxSize}
        inputProps={{
          id: "imgs",
        }}
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => (
          <>
            <UploadErrors errors={errors} />
            <div className="container">
              {initialData.map((id, index) => (
                <Card key={index} className="image">
                  <img src={cdnUrl(id)} />
                </Card>
              ))}
              {imageList.map((image, index) => (
                <Card key={index} className="image">
                  <img src={image.dataURL} />
                  <button
                    className="remove"
                    onClick={() => onImageRemove(index)}
                  >
                    <span className="sr-only">{tdt("Remove")}</span>
                    <CloseIcon aria-hidden />
                  </button>
                </Card>
              ))}
              {imageList.length + initialData.length < maxUploads && (
                <Card className="drop-target">
                  <button onClick={onImageUpload} {...dragProps}>
                    <span className="sr-only">{tdt("Click or Drop here")}</span>
                    <AddIcon aria-hidden />
                  </button>
                </Card>
              )}
            </div>
          </>
        )}
      </ImageUploading>
    </Styled.ImageUpload>
  );
};

const UploadErrors: React.FC<{ errors: ErrorsType }> = ({ errors }) => {
  let errorText = null;
  if (errors?.acceptType)
    errorText = tdt("Selected file type is not supported.");
  else if (errors?.maxFileSize) errorText = tdt("Selected file exceeds 10mb.");

  return <>{errorText && <span className="upload-error">{errorText}</span>}</>;
};

const ProductInfo: React.FC<FormItemProps> = ({ register }) => {
  return (
    <>
      <label id="name-lbl" htmlFor="name" className="h5">
        {tdt("Name")}
      </label>
      <Input
        {...register("reviewTitle")}
        id="name"
        aria-labelledby="name-lbl"
        placeholder={tdt("Product name; Business name")}
        size="medium"
        autoComplete="off"
      />

      <label id="link-lbl" htmlFor="link" className="h5">
        {tdt("Link")}
      </label>
      <div className="group">
        <Input
          {...register("link")}
          type="url"
          id="link"
          aria-labelledby="link-lbl"
          placeholder={tdt("https://")}
          size="medium"
          autoComplete="off"
        />
      </div>
    </>
  );
};

const ProsCons: React.FC<FormItemProps & { pros?: boolean }> = ({
  register,
  control,
  pros,
  watch,
}) => {
  const initPopulate = useRef(false);
  const groupName = pros ? "pros" : "cons";
  const { fields, append, remove } = useFieldArray({
    name: groupName,
    control,
    shouldUnregister: true,
  });

  useLayoutEffect(() => {
    if (!initPopulate.current) {
      if (fields.length < 5) append({ value: "" }, { shouldFocus: false });
      initPopulate.current = true;
    }
  }, [fields]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && name.startsWith(groupName)) {
        const valueCount = value[groupName].length;
        for (let i = 0; i < valueCount; ++i) {
          if (
            valueCount > 1 &&
            i !== valueCount - 1 &&
            !value[groupName][i]?.value
          ) {
            remove(i);
            break;
          } else if (
            (valueCount < 5 && value[groupName][valueCount - 1]?.value) ||
            !valueCount
          ) {
            append({ value: "" }, { shouldFocus: false });
          }
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, groupName]);

  return (
    <fieldset>
      <legend className="h5">{pros ? tdt("Pros") : tdt("Cons")}</legend>
      <div className="group">
        {fields.map((field, index) => (
          <Input
            key={field.id}
            {...register(`${groupName}.${index}.value`)}
            placeholder={tdt(`${pros ? "Pro" : "Con"} ${index + 1}`)}
            size="medium"
            autoComplete="off"
          />
        ))}
      </div>
    </fieldset>
  );
};

const questionMap: Record<string, string> = {
  surveyPurchaseAgain: tdt("Would you purchase the goods or service again?"),
  surveyRecommend: tdt("Would you recommend the goods or service to a friend?"),
};
const BoolQuestion: React.FC<
  FormItemProps & { propertyName: keyof ReviewShape }
> = ({ register, propertyName }) => (
  <div className="full-width bool-question">
    <fieldset>
      <legend className="h5">{questionMap[propertyName]}</legend>
      <div className="radio-group">
        <label className="h5">
          <Radio value="true" {...register(propertyName)} />
          {tdt("Yes")}
        </label>
        <label className="h5">
          <Radio value="false" {...register(propertyName)} />
          {tdt("No")}
        </label>
      </div>
    </fieldset>
  </div>
);

const RateBlock: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Card className={Styled.rateBlockClass}>
    <div className="row">
      <Face1Icon className="face r-1" data-ranking="1" />
      <Face2Icon className="face r-2" data-ranking="2" />
      <Face3Icon className="face r-3" data-ranking="3" />
      <Face4Icon className="face r-4" data-ranking="4" />
      <Face5Icon className="face r-5" data-ranking="5" />
    </div>
    <div className="row">
      <span className="h4 r-1">{tdt("1")}</span>
      <span className="h4 r-2">{tdt("2")}</span>
      <span className="h4 r-3">{tdt("3")}</span>
      <span className="h4 r-4">{tdt("4")}</span>
      <span className="h4 r-5">{tdt("5")}</span>
    </div>
    {children}
  </Card>
);

const rateMap: Record<string, string> = {
  rating: tdt(
    "How satisfied are you with the service or product you received?"
  ),
  surveyRateValue: tdt("Value of the goods or service."),
  surveyRateParking: tdt(
    "Product/service access, i.e. parking, ordering, and shipping."
  ),
  surveyRateReliability: tdt("Reliability of the goods or service."),
  surveyRateIntegrity: tdt("Quality of the goods or service."),
  surveyRateFeel: tdt("How did the purchase make you feel?"),
};
const RateItem: React.FC<
  FormItemProps & { propertyName: keyof ReviewShape }
> = ({ register, propertyName }) => (
  <div className="row">
    <fieldset>
      <legend className="h5 title">{rateMap[propertyName]}</legend>
      {Array(5)
        .fill(null)
        .map((_, i) => (
          <label key={i} className={`r-${i + 1}`}>
            <span className="sr-only">{tdt(`${i}`)}</span>
            <Radio value={i + 1} {...register(propertyName)} />
          </label>
        ))}
    </fieldset>
  </div>
);

const LongformBlock: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Card className="full-width">
    <Accordion>{children}</Accordion>
  </Card>
);

const longformQuestions: Record<string, string> = {
  review: tdt("Type your review here."),
  surveyAdditionalWants: tdt(
    "What additional products and services would you like to see?"
  ),
  surveyNeverStop: tdt("What should the company never stop doing?"),
  surveyHaventAsked: tdt("What haven’t we asked you yet?"),
  surveyCustomerAccess: tdt("What is your impression of the customer service?"),
};
const LongformItem: React.FC<
  FormItemProps & { propertyName: keyof ReviewShape }
> = ({ register, propertyName }) => (
  <Accordion.Item>
    <Accordion.Heading>{longformQuestions[propertyName]}</Accordion.Heading>
    <Accordion.Panel>
      <TextArea
        placeholder={tdt("...")}
        size="medium"
        rows={10}
        {...register(propertyName)}
      />
    </Accordion.Panel>
  </Accordion.Item>
);
