// TODO: replace this and everything it has w/ i18n

export const tdt = (str: string) => str;

export const formatDate = (
  date: string | Date,
  lng: string = "en-US",
  opts: any = {}
) => {
  return new Intl.DateTimeFormat(lng, { ...opts }).format(new Date(date));
};
