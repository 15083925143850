import React from "react";
import Face1Icon from "theme/inline-assets/face-1-icon.svg";
import Face2Icon from "theme/inline-assets/face-2-icon.svg";
import Face3Icon from "theme/inline-assets/face-3-icon.svg";
import Face4Icon from "theme/inline-assets/face-4-icon.svg";
import Face5Icon from "theme/inline-assets/face-5-icon.svg";

const faceIcon = {
  "1": Face1Icon,
  "2": Face2Icon,
  "3": Face3Icon,
  "4": Face4Icon,
  "5": Face5Icon,
};

// export const Stars: React.FC<{ rating: string }> = ({ rating }) => {
//   const num = Number.parseInt(rating);
//   return (
//     <>
//       {Array(num)
//         .fill(null)
//         .map((_, i) => (
//           <StarFilledIcon key={i} aria-hidden />
//         ))}
//       {Array(5 - num)
//         .fill(null)
//         .map((_, i) => (
//           <StarEmptyIcon key={`e-${i}`} aria-hidden />
//         ))}
//     </>
//   );
// };

export const Stars: React.FC<{ rating: string }> = ({ rating }) => {
  console.log({ rating });
  const Face = faceIcon[rating] ?? Face1Icon;
  return <Face />;
};
