/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    a: "a"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      children: "Contact Us"
    }), "\n", _jsx(_components.p, {
      children: "If you have any questions, if you would like to register a complaint, or if you wish to access your personal information, please contact us at the following address:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Checkayou Corporation"
      })
    }), "\n", _jsx(_components.p, {
      children: "420-16 Industrial Parkway South, Aurora, Ontario, L4G 0R4"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.a, {
        href: "mailto:info@checkayou.com",
        children: "info@checkayou.com"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
